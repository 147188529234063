import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminPrivateRoute from "../components/admin/routing/privateroute";
import UserPrivateRoute from "../components/user/routing/privateRoute";
import UserAuthPrivateRoute from "../components/user/routing/authPrivateRoute";
import Alerts from "../components/alert/index";
import Home from "../components/user/pages/home/index";
import AccountSetting from "../components/user/pages/accountSetting";
import OrderHistory from "../components/user/pages/orderHistory";
import Lists from "../components/user/pages/lists/index";
import Signup from "../components/user/auth/signup/index";
import Login from "../components/user/auth/login/index";
import ForgetPassword from "../components/user/auth/forgetPassword/index";
import VerifyNumber from "../components/user/auth/verifyCode/index";
import ResetPassword from "../components/user/auth/resetPassword/index";
import ChangePassword from "../components/user/auth/changePassword/index";
import ChangeCardDetail from "../components/user/pages/updateCard/index";
import AdminDashboard from "../components/admin/pages/dashboard/index";
// import SendMail from "../components/admin/pages/sendMail";
import SendSms from "../components/admin/pages/sendSms";
import SendBlastSms from "../components/admin/pages/sendBlastSms";
import AddUser from "../components/admin/pages/addUser";
import ShowUsers from "../components/admin/pages/users/index";
import UserDetail from "../components/admin/pages/userdetail/index";
import UpdateUser from "../components/admin/pages/updateUser";
import AddTicket from "../components/admin/pages/addTicket/index";
import ShowTicket from "../components/admin/pages/tickets/index";
import AddList from "../components/admin/pages/addLists/index";
import ShowList from "../components/admin/pages/lists/index";
import UpdateList from "../components/admin/pages/updateList/index";
import ActiveList from "../components/admin/pages/activelist";
import ShowOffer from "../components/admin/pages/offer/index";
import AddOffer from "../components/admin/pages/addOffer/index";
import UpdateOffer from "../components/admin/pages/updateOffer/index";
import Orders from "../components/admin/pages/orders/index";
// import SmsDisplay from "../components/admin/pages/smsDisplay/index";
import TicketPreview from "../components/admin/pages/ticketPreview/index";
import TicketPdf from "../components/user/pages/ticketPdf/index";
import ErrorPage from "../components/user/pages/404/index";

const Routes = () => {
  return (
    <>
      <Router>
        <Alerts />
        <Switch>
          {/* ADMIN ROUTES */}
          <AdminPrivateRoute
            path="/admin/dashboard"
            component={AdminDashboard}
          />
          {/* <AdminPrivateRoute path="/admin/sendmail" component={SendMail} /> */}
          <AdminPrivateRoute path="/admin/sendsms" component={SendSms} />
          <AdminPrivateRoute
            path="/admin/sendblastsms"
            component={SendBlastSms}
          />
          <AdminPrivateRoute path="/admin/adduser" component={AddUser} />
          <AdminPrivateRoute path="/admin/users" component={ShowUsers} />
          <AdminPrivateRoute
            path="/admin/userdetail/:token"
            component={UserDetail}
          />
          <AdminPrivateRoute
            path="/admin/updateuser/:token"
            component={UpdateUser}
          />
          <AdminPrivateRoute
            path="/admin/addtickets/:token"
            component={AddTicket}
          />
          <AdminPrivateRoute path="/admin/tickets" component={ShowTicket} />
          <AdminPrivateRoute path="/admin/addlists" component={AddList} />
          <AdminPrivateRoute path="/admin/lists" component={ShowList} />
          <AdminPrivateRoute
            path="/admin/updatelist/:token"
            component={UpdateList}
          />
          <AdminPrivateRoute path="/admin/activelists" component={ActiveList} />
          <AdminPrivateRoute path="/admin/offers" component={ShowOffer} />
          <AdminPrivateRoute
            path="/admin/ticketpreview/:token"
            component={TicketPreview}
          />
          <AdminPrivateRoute path="/admin/addoffer" component={AddOffer} />
          <AdminPrivateRoute
            path="/admin/updateoffer/:token"
            component={UpdateOffer}
          />{" "}
          <AdminPrivateRoute path="/admin/orders" component={Orders} />
          {/* <AdminPrivateRoute path="/admin/smsdisplay" component={SmsDisplay} /> */}
          {/* USER ROUTES */}
          <Route exact path="/" component={Home} />
          <Route exact path="/ticketdisplay/:token" component={TicketPdf} />
          {/* AUTHENTICATED USER ROUTE */}
          <UserAuthPrivateRoute
            exact
            path="/accountsetting"
            component={AccountSetting}
          />
          <UserAuthPrivateRoute
            exact
            path="/orderhistory"
            component={OrderHistory}
          />
          <UserAuthPrivateRoute exact path="/mylist" component={Lists} />
          <UserAuthPrivateRoute
            exact
            path="/changepassword/:id"
            component={ChangePassword}
          />
          <UserAuthPrivateRoute
            exact
            path="/changecardetail/:id"
            component={ChangeCardDetail}
          />
          {/* UNAUTHENTICATED USER ROUTE */}
          <UserPrivateRoute exact path="/signup" component={Signup} />
          <UserPrivateRoute exact path="/login" component={Login} />
          <UserPrivateRoute
            exact
            path="/forgetpassword"
            component={ForgetPassword}
          />
          <UserPrivateRoute exact path="/verifycode" component={VerifyNumber} />
          <UserPrivateRoute
            exact
            path="/resetpassword/:token"
            component={ResetPassword}
          />
          {/* 404 PAGE ROUTE */}
          <Route path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </>
  );
};
export default Routes;
