import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getTicket } from "../../../../redux/_actions/ticketAction";
import { getOffer } from "../../../../redux/_actions/offerAction";
import Loader from "../../../loader";
import "./index.css";
const Tickets = () => {
  const dispatch = useDispatch();
  const offer = useSelector((state) => state.offer);
  const ticket = useSelector((state) => state.ticket);
  const soldTicket = ticket?.tickets?.data?.filter((x) => x.isSold === true);
  const availableTicket = ticket?.tickets?.data?.filter(
    (x) => x.isSold === false
  );
  useEffect(() => {
    dispatch(getOffer());
    dispatch(getTicket());
  }, [dispatch]);
  return (
    <>
      <section className="dashboard">
        <Header />
        <div className="content-page">
          <BreadCrumb currentPage={"Tickets"} />
          <div className="dashboard-data-wrapper mt-5">
            <Container>
              <Row>
                {offer?.offers?.data ? (
                  offer?.offers?.data?.length >= 1 ? (
                    offer?.offers?.data?.map((row, i) => (
                      <Col md={4} key={i}>
                        <Link to={`/admin/addtickets/${row?._id}`}>
                          <div className="ticket-card">
                            <h2>{row?.OfferName}</h2>
                            <p>
                              {
                                ticket?.tickets?.data?.filter(
                                  (x) => x.OfferId === row?._id
                                ).length
                              }
                              / {row?.OfferQuantity}
                            </p>
                            <span>Ticket Uploaded</span>
                            <div className="ticket-detail">
                              <Container fluid>
                                <Row>
                                  <Col xs={6}>
                                    Available :
                                    <span>
                                      {
                                        availableTicket?.filter(
                                          (x) => x.OfferId === row?._id
                                        ).length
                                      }
                                    </span>
                                  </Col>
                                  <Col xs={6} className="text-right">
                                    Sold :
                                    <span>
                                      {
                                        soldTicket?.filter(
                                          (x) => x.OfferId === row?._id
                                        ).length
                                      }
                                    </span>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    ))
                  ) : (
                    <Col md={12}>
                      <p className="no-data-found">NO OFFER UPLOADED</p>
                    </Col>
                  )
                ) : (
                  <Loader />
                )}
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};
export default Tickets;
