import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import { createOffer } from "../../../../redux/_actions/offerAction";
import "./index.css";
const AddOffer = () => {
  const dispatch = useDispatch();
  const [smsOffer, setSmsOffer] = useState({
    OfferName: "",
    OfferPrice: "",
    OfferQuantity: "",
    OfferEvent: "",
    OfferDate: "",
    OfferTime: "",
    OfferLocation: "",
    OfferDetail: "",
  });
  const {
    OfferName,
    OfferPrice,
    OfferQuantity,
    OfferEvent,
    OfferDate,
    OfferTime,
    OfferLocation,
    OfferDetail,
  } = smsOffer;
  const onChange = (e) =>
    setSmsOffer({ ...smsOffer, [e.target.name]: e.target.value });
  const eventOnChange = (e) =>
    setSmsOffer({
      ...smsOffer,
      [e.target.name]: e.target.value,
    });
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createOffer(smsOffer));
  };
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Add Offer"} />
        <form className="dashboard-form" onSubmit={onSubmit}>
          <Container>
            <Row>
              <Col md={12}>
                <label>Offer Name</label>
                <input
                  type="text"
                  required
                  name="OfferName"
                  value={OfferName}
                  onChange={onChange}
                  placeholder="Enter Offer Name"
                />
              </Col>
              <Col md={6}>
                <label>Ticket Price</label>
                <input
                  type="number"
                  required
                  min={0}
                  name="OfferPrice"
                  value={OfferPrice}
                  onChange={onChange}
                  placeholder="Enter Ticket Price"
                />
              </Col>
              <Col md={6}>
                <label>Ticket Quantity</label>
                <input
                  type="number"
                  required
                  min={0}
                  name="OfferQuantity"
                  value={OfferQuantity}
                  onChange={onChange}
                  placeholder="Enter Ticket Quantity"
                />
              </Col>
              <Col md={6}>
                <label>Event Name</label>
                <input
                  type="text"
                  required
                  name="OfferEvent"
                  value={OfferEvent}
                  onChange={eventOnChange}
                  placeholder="Enter Event Name"
                />
              </Col>
              <Col md={6}>
                <label>Event Location</label>
                <input
                  type="text"
                  required
                  name="OfferLocation"
                  value={OfferLocation}
                  onChange={onChange}
                  placeholder="Enter Event Location"
                />
              </Col>
              <Col md={6}>
                <label>Event Date</label>
                <input
                  type="date"
                  required
                  name="OfferDate"
                  value={OfferDate}
                  onChange={onChange}
                  placeholder="Enter Event Date"
                />
              </Col>
              <Col md={6}>
                <label>Event Time</label>
                <input
                  type="time"
                  required
                  name="OfferTime"
                  value={OfferTime}
                  onChange={onChange}
                  placeholder="Enter Event Time"
                />
              </Col>
              <Col md={12}>
                <label>Offer Description</label>
                <textarea
                  name="OfferDetail"
                  required
                  value={OfferDetail}
                  onChange={onChange}
                  placeholder="Enter offer Description"
                ></textarea>
              </Col>
              <Col md={12} className="text-right">
                <Link to="/admin/offers">Show Offers</Link>
                <button>Add Offer</button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </section>
  );
};
export default AddOffer;
