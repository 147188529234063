import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { unsubscribeList } from "../../../../redux/_actions/listAction";
import "./index.css";

const ListUnSubscribed = (props) => {
  const dispatch = useDispatch();
  const user = useState(JSON.parse(localStorage.getItem("user_auth")));
  const handleHide = () => {
    props.unSubscribeDetailPreviewToggle();
  };
  const message = `you sucessfully unsubscribed ${props.listDetail.ListName} List.\nYou will not able to subscribe list within 48 hours.\n${window.appUrl}/\nvisit our website for more detail.\nRegards,\nTicket text`;

  const UnSubscribeList = () => {
    dispatch(
      unsubscribeList(
        props.listDetail._id,
        user[0]._id,
        user[0].phoneNumber,
        message
      )
    );
  };
  return (
    <section className="list-unsubscribe-wrapper">
      <div className="list-unsubscribe">
        <span onClick={handleHide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <h3>Are you sure you want to unsubscribe?</h3>
        <button onClick={UnSubscribeList}>UnSubscribe</button>
        <p>You will not able to subscribe list within 48 hours</p>
      </div>
    </section>
  );
};

export default ListUnSubscribed;
