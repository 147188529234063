import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getOffer } from "../../../../redux/_actions/offerAction";
import {
  getList,
  getBlastSmsList,
} from "../../../../redux/_actions/listAction";
import { getUser } from "../../../../redux/_actions/userAction";
import { createActiveList } from "../../../../redux/_actions/activelistAction";
import { SET_ALERT } from "../../../../redux/types";
import { setAlert } from "../../../../redux/_actions/alertAction";
import Loader from "../../../loader";
import dateFormat from "dateformat";
import "./index.css";
const SendBlastSms = () => {
  const dispatch = useDispatch();
  const currentTime = new Date().getTime();
  const hourSeconds = 7200000;
  const [messagePreview, setMessagePreview] = useState(false);
  const [offerDetail, setOfferDetail] = useState("");
  const [currentlist, setCurrentlist] = useState("");
  const [listSubscribesUser, setListSubscribesUser] = useState("");
  const offer = useSelector((state) => state.offer);
  const activatedOffer = offer?.offers?.data?.filter(
    (x) => x.isActive === true
  );
  const list = useSelector((state) => state.list);
  const activatedlist = list?.lists?.data?.filter((x) => x.isActive === true);
  const user = useSelector((state) => state.user.users);
  const singlelist = useSelector((state) => state.list.listblastsmslist);
  const [sendSms, setSendSms] = useState({
    listId: "",
    userId: "",
    OfferId: "",
    message:
      "TICKET TEXT OFFER: \nEvent Name [EVENT DATE] @ [TIME]\nReply YES [Event Code] [# of tickets]",
  });
  const [activelist] = useState({
    ListID: "",
    ListCode: "",
    OfferId: "",
  });
  const { listId, OfferId } = sendSms;
  function timeConvert(time) {
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? "AM" : "PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    } else {
      return "";
    }
  }
  const onListChange = (e) => {
    setSendSms({ ...sendSms, [e.target.name]: e.target.value });
    setCurrentlist(activatedlist?.find((x) => x._id === e.target.value));
    dispatch(getBlastSmsList(e.target.value));
  };
  const onOfferChange = (e) => {
    setOfferDetail(offer?.offers?.data.find((x) => x._id === e.target.value));
    setSendSms({ ...sendSms, [e.target.name]: e.target.value });
  };

  const Continue = (e) => {
    setListSubscribesUser(singlelist?.data?.listUsers);
    if (listId === "") {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Please Select List Name",
          alertType: "danger",
        })
      );
    } else if (OfferId === "") {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Please Select Offer Name",
          alertType: "danger",
        })
      );
    } else if (singlelist?.data?.listUsers?.length <= 0) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "No User Subscribed this list",
          alertType: "danger",
        })
      );
    } else {
      const OfferDate = dateFormat(offerDetail?.OfferDate, "mmmm dS, yyyy");
      sendSms.message = `TICKET TEXT OFFER: \n${
        offerDetail ? offerDetail?.OfferEvent : "Event Name"
      } ${offerDetail ? OfferDate : "[EVENT DATE]"} @ ${
        offerDetail ? timeConvert(offerDetail?.OfferTime) : "[TIME]"
      }\nReply YES ${
        currentlist ? currentlist.ListUniqueCode : "[List Code]"
      } [# of tickets]`;
      activelist.ListID = sendSms.listId;
      activelist.ListCode = currentlist.ListUniqueCode;
      activelist.OfferId = sendSms.OfferId;
      setMessagePreview(!messagePreview);
    }
  };
  useEffect(() => {
    dispatch(getOffer());
    dispatch(getList());
    dispatch(getUser());
  }, [dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createActiveList(
        activelist,
        listSubscribesUser,
        sendSms.message,
        "blastsms"
      )
    );
  };
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Send Blast Sms"} />

        {user?.length && list?.lists?.data && offer?.offers?.data ? (
          <form className="dashboard-form" onSubmit={onSubmit}>
            <Container>
              <Row>
                {!messagePreview ? (
                  <>
                    <Col md={6}>
                      <label>Select List</label>
                      <select
                        name="listId"
                        value={listId}
                        required
                        onChange={onListChange}
                      >
                        <option value="">Select List Name</option>
                        {activatedlist?.map(
                          (row, i) =>
                            currentTime - row?.ListActiveTime > hourSeconds && (
                              <option key={i} value={row?._id}>
                                {row?.ListName}
                              </option>
                            )
                        )}
                      </select>
                    </Col>
                    <Col md={6}>
                      <label>Select Offer</label>
                      <select
                        name="OfferId"
                        value={OfferId}
                        required
                        onChange={onOfferChange}
                      >
                        <option value="">Select Offer Name</option>
                        {activatedOffer?.map((row, i) => (
                          <option key={i} value={row?._id}>
                            {row?.OfferName}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={12}>
                      <label>Subscribed Users</label>
                      <div className="users-list-wrapper">
                        {singlelist?.data?.listUsers?.map((row, i) => (
                          <div className="users-list" key={i}>
                            {user?.find((x) => x._id === row)?.firstName}
                            <span className="d-none"></span>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col md={12} className="text-right">
                      <strong onClick={(e) => Continue()}>Continue</strong>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md={12}>
                      <label>Sms Display</label>
                      <textarea
                        readOnly
                        className="custom-scroll"
                        value={sendSms.message}
                        style={{ height: "100px", cursor: "auto" }}
                      />
                    </Col>
                    <Col md={12} className="text-right">
                      <strong onClick={(e) => Continue()}>Go Back</strong>
                      <button>Send Blast SMS</button>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </form>
        ) : (
          <Loader />
        )}
      </div>
    </section>
  );
};
export default SendBlastSms;
