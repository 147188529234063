import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { updateOffer } from "../../../../redux/_actions/offerAction";
const UpdateOffer = (props) => {
  const dispatch = useDispatch();
  const offerId = props.match.params.token;
  const offerdata = JSON.parse(localStorage.getItem("update_offer"));
  const [smsOffer, setSmsOffer] = useState({
    OfferName: offerdata?.OfferName,
    OfferPrice: offerdata?.OfferPrice,
    OfferQuantity: offerdata?.OfferQuantity,
    OfferEvent: offerdata?.OfferEvent,
    OfferDate: offerdata?.OfferDate,
    OfferTime: offerdata?.OfferTime,
    OfferLocation: offerdata?.OfferLocation,
    OfferDetail: offerdata?.OfferDetail,
  });

  const {
    OfferName,
    OfferPrice,
    OfferQuantity,
    OfferEvent,
    OfferDate,
    OfferTime,
    OfferLocation,
    OfferDetail,
  } = smsOffer;
  const onChange = (e) =>
    setSmsOffer({ ...smsOffer, [e.target.name]: e.target.value });
  const eventOnChange = (e) =>
    setSmsOffer({ ...smsOffer, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(updateOffer(smsOffer, offerId));
  };
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Update Offer"} />
        <div className="dashboard-data-wrapper">
          <div className="dashboard-form dashboard-readonly">
            {offerdata ? (
              <form className="dashboard-form" onSubmit={onSubmit}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <label>Offer Name</label>
                      <input
                        type="text"
                        required
                        name="OfferName"
                        value={OfferName}
                        onChange={onChange}
                        placeholder="Enter Offer Name"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Offer Price</label>
                      <input
                        type="number"
                        min={0}
                        required
                        name="OfferPrice"
                        value={OfferPrice}
                        onChange={onChange}
                        placeholder="Enter Offer Price"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Offer Quantity</label>
                      <input
                        type="number"
                        min={0}
                        required
                        name="OfferQuantity"
                        value={OfferQuantity}
                        onChange={onChange}
                        placeholder="Enter Offer Quantity"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Event Name</label>
                      <input
                        type="text"
                        required
                        name="OfferEvent"
                        value={OfferEvent}
                        onChange={eventOnChange}
                        placeholder="Enter Event Name"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Event Location</label>
                      <input
                        type="text"
                        required
                        name="OfferLocation"
                        value={OfferLocation}
                        onChange={onChange}
                        placeholder="Enter Event Location"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Event Date</label>
                      <input
                        type="date"
                        required
                        name="OfferDate"
                        value={OfferDate}
                        onChange={onChange}
                        placeholder="Enter Event Date"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Event Time</label>
                      <input
                        type="time"
                        required
                        name="OfferTime"
                        value={OfferTime}
                        onChange={onChange}
                        placeholder="Enter Event Time"
                      />
                    </Col>
                    <Col md={12}>
                      <label>Offer Description</label>
                      <textarea
                        name="OfferDetail"
                        required
                        value={OfferDetail}
                        onChange={onChange}
                        placeholder="Enter offer Description"
                      ></textarea>
                    </Col>
                    <Col md={12} className="text-right">
                      <button>Update Offer</button>
                    </Col>
                  </Row>
                </Container>
              </form>
            ) : (
              <h4 className="no-data-found">NO DATA FOUND</h4>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default UpdateOffer;
