import {
  ACTIVELIST_SUCCESS,
  ACTIVELIST_FAIL,
  ACTIVELIST_LOAD_SUCCESS,
  ACTIVELIST_LOAD_FAIL,
  SET_ALERT,
} from "../types";
import axios from "axios";
import { sendMessage, sendBlastMessage } from "./smsServiceAction";
import { setAlert } from "./alertAction";

export const getActiveList = () => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.get(`${window.appUrl}/api/activelist/`, config);
      dispatch({ type: ACTIVELIST_LOAD_SUCCESS, payload: res?.data });
    } catch (err) {
      dispatch({
        type: ACTIVELIST_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};

export const createActiveList = (
  activelist,
  phoneNumber,
  message,
  messagetype
) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/activelist/create`,
        { activelist },
        config
      );
      if (messagetype === "singlesms") {
        dispatch(sendMessage(phoneNumber, message));
      } else if (messagetype === "blastsms") {
        dispatch(sendBlastMessage(phoneNumber, message));
      }
      dispatch(
        setAlert(SET_ALERT, {
          message: "Message Send Successfully",
          alertType: "success",
        })
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      dispatch({ type: ACTIVELIST_SUCCESS, payload: res?.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: ACTIVELIST_FAIL, payload: err.response.data });
    }
  };
};
