import {
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAIL,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_SUCCESS,
  RESET_FAIL,
  CHANGE_SUCCESS,
  CHANGE_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "../types";

const initState = {
  user: {},
  error: null,
  message: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
    case USER_DETAIL_SUCCESS:
    case RESET_SUCCESS:
    case CHANGE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("user_cred", "true");
      localStorage.setItem("user_auth", JSON.stringify(action.payload.user));
      return {
        ...state,
        user: action.payload.user,
      };
    case LOGOUT:
      localStorage.clear();
      window.location.reload();
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    case USER_DETAIL_FAIL:
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case RESET_FAIL:
    case CHANGE_FAIL:
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default authReducer;
