import React from "react";
import "./index.css";
import Header from "../../partails/header/index";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/logo-icon.png";
const Home = () => {
  const userDetail = localStorage.getItem("user_cred");
  return (
    <div className="home-page">
      <Header logo={"false"} />
      <div className="page-detail">
        <img className="logo" src={Logo} alt="" />
        <h2>Ticket Deals</h2>
        <h1>Texted Straight to your Phone</h1>
        <p>
          Once Joining Ticket Text, subscribe to your favorite sports team
          and/or event interests. You will then begin to receive ticket deal
          offers directly to your phone. When a text is sent out… respond with
          YES and the # of tickets you would like to purchase, and our system
          will handle the rest by delivering your tickets to your phone!
        </p>
        {!userDetail && (
          <ul>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/signup">Signup</Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};
export default Home;
