import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";

const OrderDetail = (props) => {
  const handleHide = () => {
    props.ticketDetailToggle();
  };
  return (
    <section className="order-detail-wrapper">
      <div className="order-detail">
        <button className="close-button" onClick={handleHide}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>Ticket Details</h2>
        {props.tickets?.map((row, i) => (
          <div className="ticket-detail">
            <h5>Ticket {props.tickets.length > 1 && (i = i + 1)}</h5>
            <Link to={`/ticketdisplay/${row}`} target="_blank">
              Preview
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OrderDetail;
