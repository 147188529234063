import {
  ORDER_LOAD_SUCCESS,
  ORDER_LOAD_FAIL,
  ORDER_SINGLE_LOAD_SUCCESS,
  ORDER_SINGLE_LOAD_FAIL,
  ORDER_USER_LOAD_SUCCESS,
  ORDER_USER_SINGLE_LOAD_FAIL,
} from "../types";
const initState = {
  orders: [],
  order: null,
  userorder: null,
};

const listReducer = (state = initState, action) => {
  switch (action.type) {
    case ORDER_LOAD_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };
    case ORDER_SINGLE_LOAD_SUCCESS:
      return {
        ...state,
        order: action.payload,
      };
    case ORDER_USER_LOAD_SUCCESS:
      return {
        ...state,
        userorder: action.payload,
      };
    case ORDER_LOAD_FAIL:
    case ORDER_SINGLE_LOAD_FAIL:
    case ORDER_USER_SINGLE_LOAD_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default listReducer;
