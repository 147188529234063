import {
  LIST_SUCCESS,
  LIST_FAIL,
  LIST_LOAD_SUCCESS,
  LIST_LOAD_FAIL,
  LIST_SINGLE_LOAD_SUCCESS,
  LIST_SINGLE_LOAD_FAIL,
  LIST_BLASTSMS_LOAD_SUCCESS,
  LIST_BLASTSMS_LOAD_FAIL,
  LIST_SMS_LOAD_SUCCESS,
  LIST_SMS_LOAD_FAIL,
  LIST_SUBSCRIBE_SUCCESS,
  LIST_SUBSCRIBE_FAIL,
  LIST_UNSUBSCRIBE_SUCCESS,
  LIST_UNSUBSCRIBE_FAIL,
  LIST_USER_SUBSCRIBE_LOAD_SUCCESS,
  LIST_USER_SUBSCRIBE_LOAD_FAIL,
  LIST_USER_UNSUBSCRIBE_LOAD_SUCCESS,
  LIST_USER_UNSUBSCRIBE_LOAD_FAIL,
  LIST_UPDATE_SUCCESS,
  LIST_UPDATE_FAIL,
  LIST_DELETE_SUCCESS,
  LIST_DELETE_FAIL,
  LIST_ACTIVATE_SUCCESS,
  LIST_ACTIVATE_FAIL,
  CLEAR_ERRORS,
} from "../types";
const initState = {
  lists: [],
  list: null,
  listblastsmslist: null,
  listsmslist: null,
  subscribedList: [],
  unSubscribedList: [],
  message: null,
};

const listReducer = (state = initState, action) => {
  switch (action.type) {
    case LIST_SUCCESS:
    case LIST_SUBSCRIBE_SUCCESS:
    case LIST_UNSUBSCRIBE_SUCCESS:
    case LIST_ACTIVATE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case LIST_FAIL:
    case LIST_SUBSCRIBE_FAIL:
    case LIST_UNSUBSCRIBE_FAIL:
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case LIST_LOAD_SUCCESS:
      return {
        ...state,
        lists: action.payload,
      };
    case LIST_SINGLE_LOAD_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case LIST_BLASTSMS_LOAD_SUCCESS:
      return {
        ...state,
        listblastsmslist: action.payload,
      };
    case LIST_SMS_LOAD_SUCCESS:
      return {
        ...state,
        listsmslist: action.payload,
      };
    case LIST_USER_SUBSCRIBE_LOAD_SUCCESS:
      return {
        ...state,
        subscribedList: action.payload,
      };
    case LIST_USER_UNSUBSCRIBE_LOAD_SUCCESS:
      return {
        ...state,
        unSubscribedList: action.payload,
      };
    case LIST_LOAD_FAIL:
    case LIST_SINGLE_LOAD_FAIL:
    case LIST_BLASTSMS_LOAD_FAIL:
    case LIST_SMS_LOAD_FAIL:
    case LIST_USER_SUBSCRIBE_LOAD_FAIL:
    case LIST_USER_UNSUBSCRIBE_LOAD_FAIL:
    case LIST_UPDATE_FAIL:
    case LIST_ACTIVATE_FAIL:
      return { ...state };
    case LIST_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case LIST_DELETE_FAIL:
      return {
        ...state,
      };
    case LIST_DELETE_SUCCESS:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default listReducer;
