import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import { createList } from "../../../../redux/_actions/listAction";
import "./index.css";
const AddLists = () => {
  const dispatch = useDispatch();
  const [smslist, setSmslist] = useState({
    ListName: "",
    ListUniqueCode: "",
  });
  const { ListName, ListUniqueCode } = smslist;
  const onChange = (e) =>
    setSmslist({ ...smslist, [e.target.name]: e.target.value });
  const onCodeChange = (e) =>
    setSmslist({ ...smslist, [e.target.name]: e.target.value.toUpperCase() });
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createList(smslist));
  };
  return (
    <section className="dashboard">
      <Header />

      <div className="content-page">
        <BreadCrumb currentPage={"Add List"} />
        <form className="dashboard-form" onSubmit={onSubmit}>
          <Container>
            <Row>
              <Col md={12}>
                <label>Unique Code</label>
                <input
                  type="text"
                  required
                  maxLength={3}
                  name="ListUniqueCode"
                  value={ListUniqueCode}
                  onChange={onCodeChange}
                  placeholder="Enter Unique Code"
                />
              </Col>
              <Col md={12}>
                <label>list Name</label>
                <input
                  type="text"
                  required
                  name="ListName"
                  value={ListName}
                  onChange={onChange}
                  placeholder="Enter list Name"
                />
              </Col>
              <Col md={12} className="text-right pt-4">
                <Link to="/admin/lists">Show Lists</Link>
                <button>Add Lists</button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </section>
  );
};
export default AddLists;
