import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SideBar from "../sideBar/index";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  faChevronDown,
  faTimes,
  faBars,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../../assets/logo-icon.png";
import { logout } from "../../../../redux/_actions/authAction";
import "./index.css";
const Header = () => {
  const dispatch = useDispatch();
  const [sideBarShow, setSideBarShow] = useState("");
  const [dropdown, setdropdown] = useState("");
  const userData = JSON.parse(localStorage.getItem("user_auth"));
  const HandleDropdownChange = (e) => {
    if (dropdown === "") {
      setdropdown("true");
    } else {
      setdropdown("");
    }
  };
  const HandleSidebarChange = (e) => {
    if (sideBarShow === "") {
      setSideBarShow("true");
    } else {
      setSideBarShow("");
    }
  };
  function Logout() {
    dispatch(logout());
  }
  return (
    <>
      <header>
        <Container fluid>
          <Row>
            <Col md={6} xs={4}>
              <div className="logoBox">
                <Link to="/">
                  <img className="w-100" src={Logo} alt="" />
                </Link>
              </div>
            </Col>
            <Col md={6} xs={8}>
              <div className="dropdown-wrapper">
                <div className="dropdown-button">
                  <span
                    className="dropdown-button"
                    onClick={(e) => HandleDropdownChange(e)}
                  >
                    {userData.firstName}

                    {dropdown === "true" ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </span>
                  {dropdown === "true" && (
                    <div className="dropdown-content">
                      <Link to="/accountsetting">Setting</Link>
                      <span onClick={(e) => Logout()}>Logout</span>
                    </div>
                  )}
                </div>
              </div>
              <button
                className="sidebar-toggle"
                onClick={(e) => HandleSidebarChange(e)}
              >
                {sideBarShow === "true" ? (
                  <FontAwesomeIcon icon={faTimes} />
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </button>
            </Col>
          </Row>
        </Container>
      </header>
      {sideBarShow === "true" ? <SideBar show={"show"} /> : <SideBar />}
    </>
  );
};
export default Header;
