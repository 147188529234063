import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
const SideBar = (props) => {
  return (
    <aside
      className={
        props.show === "show"
          ? "side-bar side-bar-show custom-scroll"
          : "side-bar custom-scroll"
      }
    >
      <ul>
        <li>
          <Link to="/admin/dashboard">Dashboard</Link>
        </li>
        {/* <li>
          <Link to="/admin/sendmail">Send Mail</Link>
        </li> */}
        {/* <li>
          <Link to="/admin/smsdisplay">Sms List</Link>
        </li> */}
        <li>
          <Link to="/admin/sendsms">Send Single Sms</Link>
        </li>
        <li>
          <Link to="/admin/sendblastsms">Send Blast Sms</Link>
        </li>
        <li>
          <Link to="/admin/users">Users</Link>
        </li>
        <li>
          <Link to="/admin/lists">Lists</Link>
        </li>
        <li>
          <Link to="/admin/activelists">Active Lists</Link>
        </li>
        <li>
          <Link to="/admin/offers">Offers</Link>
        </li>
        <li>
          <Link to="/admin/tickets">Tickets</Link>
        </li>
        <li>
          <Link to="/admin/orders">Orders</Link>
        </li>
        <li>
          <Link to="/accountsetting">Setting</Link>
        </li>
      </ul>
    </aside>
  );
};
export default SideBar;
