import {
  SENDMESSAGE_SUCCESS,
  SENDMESSAGE_FAIL,
  SENDBLASTMESSAGE_SUCCESS,
  SENDBLASTMESSAGE_FAIL,
  SENDCODE_SUCCESS,
  SENDCODE_FAIL,
  VERIFYCODE_SUCCESS,
  VERIFYCODE_FAIL,
  SET_ALERT,
} from "../types";
import axios from "axios";
import { setAlert } from "./alertAction";
import { register } from "./authAction";

export const sendMessage = (phoneNumber, message) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/sms/sendmessage`,
        { phoneNumber, message },
        config
      );
      dispatch({ type: SENDMESSAGE_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: SENDMESSAGE_FAIL, payload: err.response.data });
    }
  };
};
export const sendBlastMessage = (phoneNumber, message, refresh) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/sms/sendblastmessage`,
        { phoneNumber, message },
        config
      );
      dispatch(
        setAlert(SET_ALERT, {
          message: "Messages Send Successfully",
          alertType: "success",
        })
      );
      if (refresh === "yes") {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      dispatch({ type: SENDBLASTMESSAGE_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response?.data?.message,
          alertType: "danger",
        })
      );
      dispatch({ type: SENDBLASTMESSAGE_FAIL, payload: err.response.data });
    }
  };
};
export const sendCode = (phoneNumber) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/sms/sendcode`,
        { phoneNumber },
        config
      );
      dispatch(
        setAlert(SET_ALERT, {
          message: "OTP Code has been sent to your number",
          alertType: "success",
        })
      );
      window.location.replace("/verifycode");
      dispatch({ type: SENDCODE_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: SENDCODE_FAIL, payload: err.response.data });
    }
  };
};

export const verifyCode = (data) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const userr = JSON.parse(localStorage.getItem("user_detail"));
      const cardId = JSON.parse(localStorage.getItem("user_cust"));
      const res = await axios.post(
        `${window.appUrl}/api/sms/verifycode`,
        data,
        config
      );
      dispatch(register(userr, cardId));
      dispatch({ type: VERIFYCODE_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Invalid Code",
          alertType: "danger",
        })
      );
      dispatch({ type: VERIFYCODE_FAIL, payload: err.response.data });
    }
  };
};
