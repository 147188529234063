import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../../../redux/_actions/authAction";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../assets/logo.png";
import "./index.css";
const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [forgetemail, setForgetemail] = useState("");
  const [Error, setError] = useState("");
  function onChange(e) {
    setForgetemail(e.target.value.toString().toLowerCase());
    setError("false");
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (forgetemail === "") {
      setError("true");
    } else {
      dispatch(forgetPassword(forgetemail));
    }
  };
  return (
    <>
      <div className="forgetpassword-wrapper">
        <form className="forgetpassword" onSubmit={onSubmit}>
          <div className="logo-box">
            <img className="w-100" src={Logo} alt="" />
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <label>Enter Email Address</label>
                <input
                  type="email"
                  name="forgetemail"
                  value={forgetemail}
                  onChange={onChange}
                  placeholder="Enter Email Address To Recive link "
                />
                {Error === "true" && (
                  <span className="errormessage ">
                    Email Address is required
                  </span>
                )}
              </Col>
              <Col md={12}>
                <button>Send Link</button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  );
};
export default ForgetPassword;
