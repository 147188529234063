export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const CHANGE_SUCCESS = "CHANGE_SUCCESS";
export const CHANGE_FAIL = "CHANGE_FAIL";

export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";

export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAIL = "USER_DETAIL_FAIL";

export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAIL = "USER_CREATE_FAIL";

export const USER_LOAD_SUCCESS = "USER_LOAD_SUCCESS";
export const USER_LOAD_FAIL = "USER_LOAD_FAIL";

export const USER_SINGLE_LOAD_SUCCESS = "USER_SINGLE_LOAD_SUCCESS";
export const USER_SINGLE_LOAD_FAIL = "USER_SINGLE_LOAD_FAIL";

export const USER_LOAD_CARDDETAIL_SUCCESS = "USER_LOAD_CARDDETAIL_SUCCESS";
export const USER_LOAD_CARDDETAIL_FAIL = "USER_LOAD_CARDDETAIL_FAIL";

export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";

export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_ACTIVATE_SUCCESS = "USER_ACTIVATE_SUCCESS";
export const USER_ACTIVATE_FAIL = "USER_ACTIVATE_FAIL";

export const SENDMESSAGE_SUCCESS = "SENDMESSAGE_SUCCESS";
export const SENDMESSAGE_FAIL = "SENDMESSAGE_FAIL";

export const SENDBLASTMESSAGE_SUCCESS = "SENDBLASTMESSAGE_SUCCESS";
export const SENDBLASTMESSAGE_FAIL = "SENDBLASTMESSAGE_FAIL";

export const SENDCODE_SUCCESS = "SENDCODE_SUCCESS";
export const SENDCODE_FAIL = "SENDCODE_FAIL";

export const VERIFYCODE_SUCCESS = "VERIFYCODE_SUCCESS";
export const VERIFYCODE_FAIL = "VERIFYCODE_FAIL";

export const LIST_SUCCESS = "LIST_SUCCESS";
export const LIST_FAIL = "LIST_FAIL";

export const LIST_LOAD_SUCCESS = "LIST_LOAD_SUCCESS";
export const LIST_LOAD_FAIL = "LIST_LOAD_FAIL";

export const LIST_SINGLE_LOAD_SUCCESS = "LIST_SINGLE_LOAD_SUCCESS";
export const LIST_SINGLE_LOAD_FAIL = "LIST_SINGLE_LOAD_FAIL";

export const LIST_BLASTSMS_LOAD_SUCCESS = "LIST_BLASTSMS_LOAD_SUCCESS";
export const LIST_BLASTSMS_LOAD_FAIL = "LIST_BLASTSMS_LOAD_FAIL";

export const LIST_SMS_LOAD_SUCCESS = "LIST_SMS_LOAD_SUCCESS";
export const LIST_SMS_LOAD_FAIL = "LIST_SMS_LOAD_FAIL";

export const LIST_USER_SUBSCRIBE_LOAD_SUCCESS =
  "LIST_USER_SUBSCRIBE_LOAD_SUCCESS";
export const LIST_USER_SUBSCRIBE_LOAD_FAIL = "LIST_USER_SUBSCRIBE_LOAD_FAIL";

export const LIST_USER_UNSUBSCRIBE_LOAD_SUCCESS =
  "LIST_USER_UNSUBSCRIBE_LOAD_SUCCESS";
export const LIST_USER_UNSUBSCRIBE_LOAD_FAIL =
  "LIST_USER_UNSUBSCRIBE_LOAD_FAIL";

export const LIST_SUBSCRIBE_SUCCESS = "LIST_SUBSCRIBE_SUCCESS";
export const LIST_SUBSCRIBE_FAIL = "LIST_SUBSCRIBE_FAIL";

export const LIST_UNSUBSCRIBE_SUCCESS = "LIST_UNSUBSCRIBE_SUCCESS";
export const LIST_UNSUBSCRIBE_FAIL = "LIST_UNSUBSCRIBE_FAIL";

export const LIST_UPDATE_SUCCESS = "LIST_UPDATE_SUCCESS";
export const LIST_UPDATE_FAIL = "LIST_UPDATE_FAIL";

export const LIST_DELETE_SUCCESS = "LIST_DELETE_SUCCESS";
export const LIST_DELETE_FAIL = "LIST_DELETE_FAIL";

export const LIST_ACTIVATE_SUCCESS = "LIST_ACTIVATE_SUCCESS";
export const LIST_ACTIVATE_FAIL = "LIST_ACTIVATE_FAIL";

export const OFFER_SUCCESS = "OFFER_SUCCESS";
export const OFFER_FAIL = "OFFER_FAIL";

export const OFFER_LOAD_SUCCESS = "OFFER_LOAD_SUCCESS";
export const OFFER_LOAD_FAIL = "OFFER_LOAD_FAIL";

export const OFFER_UPDATE_SUCCESS = "OFFER_UPDATE_SUCCESS";
export const OFFER_UPDATE_FAIL = "OFFER_UPDATE_FAIL";

export const OFFER_DELETE_SUCCESS = "OFFER_DELETE_SUCCESS";
export const OFFER_DELETE_FAIL = "OFFER_DELETE_FAIL";

export const OFFER_ACTIVATE_SUCCESS = "OFFER_ACTIVATE_SUCCESS";
export const OFFER_ACTIVATE_FAIL = "OFFER_ACTIVATE_FAIL";

export const TICKET_SUCCESS = "TICKET_SUCCESS";
export const TICKET_FAIL = "TICKET_FAIL";

export const TICKET_UPDATE_SUCCESS = "TICKET_UPDATE_SUCCESS";
export const TICKET_UPDATE_FAIL = "TICKET_UPDATE_FAIL";

export const TICKET_LOAD_SUCCESS = "TICKET_LOAD_SUCCESS";
export const TICKET_LOAD_FAIL = "TICKET_LOAD_FAIL";
export const TICKET_EMPTY_SET = "TICKET_EMPTY_SET";

export const TICKET_DELETE_SUCCESS = "TICKET_DELETE_SUCCESS";
export const TICKET_DELETE_FAIL = "TICKET_DELETE_FAIL";

export const ACTIVELIST_SUCCESS = "ACTIVELIST_SUCCESS";
export const ACTIVELIST_FAIL = "ACTIVELIST_FAIL";

export const ACTIVELIST_LOAD_SUCCESS = "ACTIVELIST_LOAD_SUCCESS";
export const ACTIVELIST_LOAD_FAIL = "ACTIVELIST_LOAD_FAIL";

export const ORDER_LOAD_SUCCESS = "ORDER_LOAD_SUCCESS";
export const ORDER_LOAD_FAIL = "ORDER_LOAD_FAIL";

export const ORDER_SINGLE_LOAD_SUCCESS = "ORDER_SINGLE_LOAD_SUCCESS";
export const ORDER_SINGLE_LOAD_FAIL = "ORDER_SINGLE_LOAD_FAIL";

export const ORDER_USER_LOAD_SUCCESS = "ORDER_USER_LOAD_SUCCESS";
export const ORDER_USER_SINGLE_LOAD_FAIL = "ORDER_USER_SINGLE_LOAD_FAIL";

export const PAYMENT_DETAIL_SUCCESS = "PAYMENT_DETAIL_SUCCESS";
export const PAYMENT_DETAIL_FAIL = "PAYMENT_DETAIL_FAIL";

export const PAYMENT_UPDATE_CUSTOMER_SUCCESS =
  "PAYMENT_UPDATE_CUSTOMER_SUCCESS";
export const PAYMENT_UPDATE_CUSTOMER_FAIL = "PAYMENT_UPDATE_CUSTOMER_FAIL";

export const PAYMENT_DETAIL_ADMIN_SUCCESS = "PAYMENT_DETAIL_ADMIN_SUCCESS";
export const PAYMENT_DETAIL_ADMIN_FAIL = "PAYMENT_DETAIL_ADMIN_FAIL";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
