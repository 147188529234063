import React from "react";
import "./index.css";
const States = (props) => {
  return (
    <div className="progress-card">
      <h6>
        {props.statename} <span>{props.stateLength}</span>
      </h6>
    </div>
  );
};
export default States;
