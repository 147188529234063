import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.css";
import PageTitle from "../../partails/pageTitle";
import Header from "../../partails/header";
import ConfirmMessage from "../../../confirmMessage";
import { updateUser, deleteUser } from "../../../../redux/_actions/userAction";
const AccountSetting = () => {
  const dispatch = useDispatch();
  const [userDeletePreviewShown, setDeleteUserPreviewShown] = useState(false);
  const userDetail = JSON.parse(localStorage.getItem("user_auth"));
  const userDelete = () => {
    dispatch(deleteUser(userDetail?._id, "customer"));
  };
  const userDeleteToggle = () => {
    setDeleteUserPreviewShown(!userDeletePreviewShown);
  };
  const [newUser, setNewUser] = useState({
    firstName: userDetail?.firstName,
    lastName: userDetail?.lastName,
    email: userDetail?.email,
    billingAddress: userDetail?.billingAddress,
    billingCity: userDetail?.billingCity,
    billingState: userDetail?.billingState,
    role: userDetail?.role,
    isActive: "true",
  });
  const {
    firstName,
    lastName,
    email,
    billingAddress,
    billingCity,
    billingState,
  } = newUser;
  const onChange = (e) =>
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  const emailOnChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value.toString().toLowerCase(),
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUser(newUser, userDetail?._id, "user"));
  };
  return (
    <>
      <Header logo={"true"} />
      <PageTitle PageTitle={"Account Setting"} />
      <div className="accountsetting-wrapper">
        <form className="accountsetting" onSubmit={onSubmit}>
          <div className="text-right"></div>
          <Container>
            <Row>
              <Col md={12}>
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={emailOnChange}
                  placeholder="Email Address"
                />
              </Col>
              <Col md={6}>
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={onChange}
                  placeholder="First Name"
                />
              </Col>
              <Col md={6}>
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={onChange}
                  placeholder="Last Name"
                />
              </Col>
              <Col md={12}>
                <label>Password</label>
                <div className="password-box position-relative">
                  <input
                    readOnly
                    type="password"
                    value="*******************"
                    placeholder="Password"
                  />
                  <Link to={`/changepassword/${userDetail?._id}`}>
                    Change Password
                  </Link>
                </div>
              </Col>
              {userDetail?.role !== "admin" && (
                <>
                  <Col md={12}>
                    <label>Card Detail</label>
                    <div className="password-box position-relative">
                      <input
                        readOnly
                        type="text"
                        value="*******************"
                        placeholder="Card Number"
                      />
                      <Link to={`/changecardetail/${userDetail?._id}`}>
                        Update Card
                      </Link>
                    </div>
                  </Col>
                  <Col md={12}>
                    <label>Billing Address</label>
                    <input
                      type="text"
                      name="billingAddress"
                      value={billingAddress}
                      onChange={onChange}
                      placeholder="Billing Address"
                    />
                  </Col>
                  <Col md={6}>
                    <label>Billing City</label>
                    <input
                      type="text"
                      name="billingCity"
                      value={billingCity}
                      onChange={onChange}
                      placeholder="Billing City"
                    />
                  </Col>
                  <Col md={6}>
                    <label>Billing State</label>
                    <input
                      type="text"
                      name="billingState"
                      value={billingState}
                      onChange={onChange}
                      placeholder="Billing State"
                    />
                  </Col>
                </>
              )}
              <Col md={12}>
                <button>Update Account</button>
                {userDetail?.role !== "admin" && (
                  <span
                    className="deactivate-account"
                    onClick={(e) => userDeleteToggle()}
                  >
                    Deativate Account
                  </span>
                )}
              </Col>
            </Row>
          </Container>
        </form>
      </div>
      {userDeletePreviewShown && (
        <ConfirmMessage
          title="Are you sure you want to deactivate your account?"
          dispathFunction={userDelete}
          HideFunction={userDeleteToggle}
        />
      )}
    </>
  );
};

export default AccountSetting;
