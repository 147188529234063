import {
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  RESET_SUCCESS,
  RESET_FAIL,
  CHANGE_SUCCESS,
  CHANGE_FAIL,
  LOGOUT,
  SET_ALERT,
} from "../types";
import axios from "axios";
import { setAlert } from "./alertAction";
import { sendMessage } from "./smsServiceAction";

export const getUserDetail = (userId) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${window.appUrl}/api/auth/getuserdetail/` + userId,
        config
      );
      if (res.data?.data?.isActive === false) {
        dispatch(logout());
      }
      dispatch({ type: USER_DETAIL_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch({
        type: USER_DETAIL_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};
export const register = (user, cardId) => {
  const phoneNumber = user.phoneNumber;
  const message = `Hello ${user.firstName}! \nThank you for signing up for Ticket Text! We are super excited to have you with us.\nSubscribe to lists that interest you via 
 the ${window.appUrl}/ web platform.\nPlease do not hesitate to reach out with any questions or issues\nReply STOP to unsubscribe`;

  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/auth/register`,
        { user, cardId },
        config
      );
      dispatch(
        setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
      );
      dispatch(sendMessage(phoneNumber, message));
      setTimeout(() => {
        localStorage.clear("user_detail");
        localStorage.removeItem("user_cust");
        window.location.replace("/login");
      }, 2000);
      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: REGISTER_FAIL, payload: err.response.data });
    }
  };
};
export const login = (crediential) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.post(
        `${window.appUrl}/api/auth/login`,
        crediential,
        config
      );

      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      dispatch(
        setAlert(SET_ALERT, {
          message: "Login Successfully.",
          alertType: "success",
        })
      );
      window.location.replace("/");
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: LOGIN_FAIL, payload: err.response.data.message });
    }
  };
};
export const forgetPassword = (email) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.post(
        `${window.appUrl}/api/auth/forgetpassword`,
        { email },
        config
      );
      dispatch(
        setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
      );
      window.location.replace("/");
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
    }
  };
};
export const resetpassword = (password, resetToken) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.post(
        `${window.appUrl}/api/auth/reset/` + resetToken,
        { password },
        config
      );
      dispatch(
        setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
      );
      dispatch({ type: RESET_SUCCESS, payload: res.data });
      window.location.replace("/login");
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: RESET_FAIL, payload: err.response.data.message });
    }
  };
};
export const changePassword = (password, oldpassword, id) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.post(
        `${window.appUrl}/api/auth/changepassword/` + id,
        { password, oldpassword },
        config
      );
      dispatch(
        setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
      );
      dispatch({ type: CHANGE_SUCCESS, payload: res.data });
      setTimeout(() => {
        window.location.replace("/");
      }, 2000);
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response?.data?.message,
          alertType: "danger",
        })
      );
      dispatch({ type: CHANGE_FAIL, payload: err.response?.data?.message });
    }
  };
};
export const logout = () => (dispatch) => {
  dispatch(
    setAlert(SET_ALERT, { message: "Logout Sucessfully", alertType: "danger" })
  );
  dispatch({ type: LOGOUT });
};
