import {
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_LOAD_SUCCESS,
  USER_LOAD_FAIL,
  USER_LOAD_CARDDETAIL_SUCCESS,
  USER_LOAD_CARDDETAIL_FAIL,
  USER_SINGLE_LOAD_SUCCESS,
  USER_SINGLE_LOAD_FAIL,
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_SUCCESS,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_SUCCESS,
  SET_ALERT,
} from "../types";
import axios from "axios";
import { setAlert } from "./alertAction";
import { sendMessage } from "./smsServiceAction";
import { logout } from "./authAction";

export const createUserr = (user, cardId) => {
  const phoneNumber = user.phoneNumber;
  const message = `Hello ${user.firstName}! \nThanks for signing on Ticket text.\nWe love you having with us.You can expect to hear sms from us with special offers.\nReply STOP to unsubscribe`;

  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/auth/register`,
        { user, cardId },
        config
      );
      dispatch(
        setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
      );
      dispatch(sendMessage(phoneNumber, message));
      setTimeout(() => {
        window.location.replace("/admin/users");
      }, 2000);
      dispatch({ type: USER_CREATE_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: USER_CREATE_FAIL, payload: err.response.data });
    }
  };
};

export const getUser = () => async (dispatch) => {
  try {
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.get(`${window.appUrl}/api/user/`, config);

    dispatch({ type: USER_LOAD_SUCCESS, payload: res?.data });
  } catch (err) {
    dispatch({ type: USER_LOAD_FAIL, payload: err.response?.data?.message });
  }
};

export const getUserById = (userId) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${window.appUrl}/api/user/` + userId,
        config
      );

      dispatch({ type: USER_SINGLE_LOAD_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch({
        type: USER_SINGLE_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};

export const getUserCardDetail = (userId) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${window.appUrl}/api/user/usercarddetail/` + userId,
        config
      );

      dispatch({ type: USER_LOAD_CARDDETAIL_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch({
        type: USER_LOAD_CARDDETAIL_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};

export const updateUser = (user, id, userRole) => async (dispatch) => {
  try {
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.patch(
      `${window.appUrl}/api/user/update/` + id,
      user,
      config
    );
    dispatch(
      setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
    );
    dispatch({ type: USER_UPDATE_SUCCESS, payload: res.data });
    if (userRole === "user") {
      localStorage.setItem("user_auth", JSON.stringify(res.data.user));
      window.location.replace("/");
    } else {
      localStorage.removeItem("update_user");
      window.location.replace("/admin/users");
    }
  } catch (err) {
    dispatch({ type: USER_UPDATE_FAIL, payload: err.message });
  }
};

export const deleteUser = (id, currentUser) => async (dispatch) => {
  try {
    const CrntUser = currentUser;
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.delete(`${window.appUrl}/api/user/` + id, config);
    dispatch(
      setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
    );
    if (CrntUser === "customer") {
      setTimeout(() => {
        dispatch(logout());
      }, 1000);
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    dispatch({ type: USER_DELETE_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: USER_DELETE_FAIL, payload: err.message });
  }
};

export const activateUser = (id) => async (dispatch) => {
  try {
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.patch(
      `${window.appUrl}/api/user/activateuser/` + id,
      config
    );
    dispatch(
      setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
    );
    setTimeout(() => {
      window.location.reload();
    }, 1000);

    dispatch({ type: USER_ACTIVATE_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: USER_ACTIVATE_FAIL, payload: err.message });
  }
};
