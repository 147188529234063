import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loader from "../../../loader";
import ConfirmMessage from "../../../confirmMessage";
import {
  getUserById,
  deleteUser,
  activateUser,
} from "../../../../redux/_actions/userAction";
import { getUserSubscribedList } from "../../../../redux/_actions/listAction";
import "./index.css";
const UserDetail = (props) => {
  const dispatch = useDispatch();
  const [userDeletePreviewShown, setDeleteUserPreviewShown] = useState(false);
  const [userActivatePreviewShown, setActivateUserPreviewShown] =
    useState(false);
  const userId = props?.match.params.token;
  const userdata = useSelector((state) => state.user.user);
  const UserList = useSelector((state) => state.list.subscribedList);
  const userDelete = () => {
    dispatch(deleteUser(userId));
  };
  const userDeleteToggle = () => {
    setDeleteUserPreviewShown(!userDeletePreviewShown);
  };
  const userActivate = () => {
    dispatch(activateUser(userId));
  };
  const userActivateToggle = () => {
    setActivateUserPreviewShown(!userActivatePreviewShown);
  };
  useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(getUserSubscribedList(userId));
  }, [dispatch]);
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"User Detail"} />
        <div className="dashboard-data-wrapper">
          <div className="dashboard-form dashboard-readonly">
            {userdata?.data ? (
              <Container>
                <Row>
                  <Col md={6}>
                    {userdata?.data?.isActive === false && (
                      <p className="alert-message-box">
                        This Account is not Active
                      </p>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className="text-right">
                      {userdata?.data?.isActive === true ? (
                        <span
                          className="deactivate-account"
                          onClick={(e) => userDeleteToggle()}
                        >
                          Deactivate Account
                        </span>
                      ) : (
                        <span
                          className="activate-account"
                          onClick={(e) => userActivateToggle()}
                        >
                          Activate Account
                        </span>
                      )}
                      <Link to="/admin/users">Go Back</Link>
                    </div>
                  </Col>
                  <Col lg={5} className="border-right">
                    <Container>
                      <Row>
                        <Col md={12}>
                          <label> First Name</label>
                          <input
                            type="text"
                            readOnly
                            value={userdata?.data?.firstName}
                          />
                          <label> Last Name</label>
                          <input
                            type="text"
                            readOnly
                            value={userdata?.data?.lastName}
                          />
                          <label> Email Address</label>
                          <input
                            type="text"
                            readOnly
                            value={userdata?.data?.email}
                          />
                          <label> Phone Number</label>
                          <input
                            type="text"
                            readOnly
                            value={userdata?.data?.phoneNumber}
                          />
                          <label> Billing Address</label>
                          <input
                            type="text"
                            readOnly
                            value={userdata?.data?.billingAddress}
                          />
                        </Col>
                        <Col md={6}>
                          <label> Billing City</label>
                          <input
                            type="text"
                            readOnly
                            value={userdata?.data?.billingCity}
                          />
                        </Col>
                        <Col md={6}>
                          <label> Billing State</label>
                          <input
                            type="text"
                            readOnly
                            value={userdata?.data?.billingState}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col lg={7}>
                    <div className="user-subscribe-list-detail">
                      <h5>Subscribed List</h5>
                      <Container>
                        <Row>
                          {UserList?.data?.length > 0 ? (
                            <Col lg={12}>
                              {UserList?.data?.map((row) => (
                                <div className="list-detail">
                                  <h3>
                                    {row?.ListName}
                                    {/* <span>
                                      <FontAwesomeIcon icon={faTrash} />
                                    </span> */}
                                  </h3>
                                </div>
                              ))}
                            </Col>
                          ) : (
                            <Col lg={12}>
                              <h2 className="no-data-found">
                                No list Subscribed
                              </h2>
                            </Col>
                          )}
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </Container>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
      {userDeletePreviewShown && (
        <ConfirmMessage
          title="Are you sure you want to deactivate this Account?"
          dispathFunction={userDelete}
          HideFunction={userDeleteToggle}
        />
      )}
      {userActivatePreviewShown && (
        <ConfirmMessage
          title="Are you sure you want to activate this Account?"
          dispathFunction={userActivate}
          HideFunction={userActivateToggle}
        />
      )}
    </section>
  );
};
export default UserDetail;
