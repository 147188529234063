import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/_actions/alertAction";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Loader from "../../../loader";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import SimpleFileUpload from "react-simple-file-upload";
import { addTicket, getTicket } from "../../../../redux/_actions/ticketAction";
import { getOffer } from "../../../../redux/_actions/offerAction";
import "./index.css";
const Addticket = (props) => {
  const dispatch = useDispatch();
  const [fileImage, setfileImage] = useState(null);
  const OfferId = props?.match.params.token;
  const offer = useSelector((state) => state.offer);
  const ticketOffer = offer?.offers?.data?.find((x) => x._id === OfferId);
  const ticket = useSelector((state) => state.ticket);
  const offerTickets = ticket?.tickets?.data?.filter(
    (x) => x.OfferId === OfferId
  );
  const ticketId =
    ticketOffer?.OfferName?.replace(/ /g, "").toString().toLowerCase() +
    parseInt(
      ticket?.tickets?.data?.filter((x) => x.OfferId === OfferId).length + 1
    ).toLocaleString(undefined, {
      useGrouping: false,
      minimumIntegerDigits: 5,
    });
  const [smsticket, setSmsticket] = useState({
    TicketDescription: "",
    TicketImage: "",
  });
  const { TicketImage, TicketDescription } = smsticket;
  const onChange = (e) =>
    setSmsticket({ ...smsticket, [e.target.name]: e.target.value });
  useEffect(() => {
    dispatch(getOffer());
    dispatch(getTicket());
  }, [dispatch]);

  function handleFile(url) {
    smsticket.TicketImage = url;
    setfileImage(url);
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if (TicketImage === "" || TicketDescription === "") {
      dispatch(setAlert("Please enter all the fields.", "danger"));
    } else {
      dispatch(
        addTicket(
          ticketId,
          smsticket.TicketImage,
          smsticket.TicketDescription,
          OfferId
        )
      );
    }
  };
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Add ticket"} />
        {ticketOffer ? (
          <>
            <form
              className="dashboard-form mb-5"
              onSubmit={onSubmit}
              encType="multipart/form-data"
            >
              <Container>
                <Row>
                  <Col md={6}>
                    <h4 className="uploading-tickets">
                      Uploading Tickets on
                      <span> {ticketOffer?.OfferName}</span>
                    </h4>
                  </Col>
                  <Col md={6}>
                    <h4 className="uploaded-tickets">
                      Uploaded Tickets:
                      <span className="ml-2">
                        {
                          ticket?.tickets?.data?.filter(
                            (x) => x.OfferId === OfferId
                          ).length
                        }
                        /{ticketOffer?.OfferQuantity}
                      </span>
                    </h4>
                  </Col>
                  {ticket?.tickets?.data?.filter((x) => x.OfferId === OfferId)
                    .length !== ticketOffer?.OfferQuantity ? (
                    <>
                      <Col md={6}>
                        <label>Ticket Image</label>

                        <div
                          className={
                            TicketImage !== ""
                              ? `droparea-active droparea`
                              : `droparea`
                          }
                        >
                          <SimpleFileUpload
                            apiKey="9eacdce407f596df08cabe75408c5efb"
                            onSuccess={handleFile}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <label className="mt-4">Ticket ID</label>
                        <input
                          type="text"
                          readOnly
                          style={{ cursor: "auto" }}
                          value={ticketId}
                        />
                        <label>Ticket Description</label>
                        <textarea
                          required
                          name="TicketDescription"
                          value={TicketDescription}
                          onChange={onChange}
                          placeholder="Enter ticket description"
                          style={{ height: "150px " }}
                        ></textarea>
                      </Col>
                      <Col md={4}>
                        {fileImage && (
                          <div className="pdf-preview">
                            <a href={fileImage} target="_blank">
                              Preview PDF
                            </a>
                          </div>
                        )}
                      </Col>
                      <Col md={8} className="text-right">
                        <Link to="/admin/tickets">Show Tickets</Link>
                        <button>Add Ticket</button>
                      </Col>
                    </>
                  ) : (
                    <h2 className="all-tickets-uploaded">
                      All Tickets uploaded
                    </h2>
                  )}
                </Row>
              </Container>
            </form>
            <h2 className="uploaded-ticket-title">
              Tickets Detail Of {ticketOffer?.OfferName}
            </h2>
            <div className="dashboard-data-wrapper">
              <Table striped bordered hover className="dashboard-data mt-5">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Ticket Id</th>
                    <th>Ticket Description</th>
                    <th>Ticket Detail</th>
                    <th></th>
                  </tr>
                </thead>
                {offerTickets?.length > 0 ? (
                  <tbody>
                    {offerTickets?.map((row, i) => (
                      <tr key={i}>
                        <td>{(i = i + 1)}</td>
                        <td>{row?.TicketID}</td>
                        <td>{row?.TicketDescription}</td>
                        <td>{row?.isSold === true ? "Sold" : "Available"}</td>
                        <td className="text-center">
                          <a href={row?.TicketImage} target="_blank">
                            <span className="edit">
                              <FontAwesomeIcon icon={faEye} />
                            </span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <th colSpan="5" className="text-center py-5 bg-white ">
                        NO TICKETS UPLOADED
                      </th>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </section>
  );
};
export default Addticket;
