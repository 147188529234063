import {
  OFFER_SUCCESS,
  OFFER_FAIL,
  OFFER_LOAD_SUCCESS,
  OFFER_LOAD_FAIL,
  OFFER_UPDATE_SUCCESS,
  OFFER_UPDATE_FAIL,
  OFFER_DELETE_SUCCESS,
  OFFER_DELETE_FAIL,
  OFFER_ACTIVATE_SUCCESS,
  OFFER_ACTIVATE_FAIL,
  CLEAR_ERRORS,
} from "../types";
const initState = {
  offers: [],
  message: null,
};

const offerReducer = (state = initState, action) => {
  switch (action.type) {
    case OFFER_SUCCESS:
    case OFFER_ACTIVATE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case OFFER_FAIL:
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case OFFER_LOAD_SUCCESS:
      return {
        ...state,
        offers: action.payload,
      };
    case OFFER_UPDATE_SUCCESS:
    case OFFER_LOAD_FAIL:
    case OFFER_UPDATE_FAIL:
    case OFFER_ACTIVATE_FAIL:
    case OFFER_DELETE_FAIL:
    case OFFER_DELETE_SUCCESS:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default offerReducer;
