import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../partials/header/index";
import States from "../../partials/states";
import { getOffer } from "../../../../redux/_actions/offerAction";
import { getList } from "../../../../redux/_actions/listAction";
import { getUser } from "../../../../redux/_actions/userAction";
import { getActiveList } from "../../../../redux/_actions/activelistAction";
import { getOrders } from "../../../../redux/_actions/orderAction";
import Loader from "../../../loader";
import "./index.css";
const Home = () => {
  const dispatch = useDispatch();
  const currentTime = new Date().getTime();
  const hourSeconds = 7200000;
  const offer = useSelector((state) => state.offer);
  const list = useSelector((state) => state.list);
  const user = useSelector((state) => state.user);
  const order = useSelector((state) => state.order);
  const activeList = useSelector((state) => state.activelist);
  const currentlyActiveList = activeList?.activelists?.data?.filter(
    (x) => currentTime - x.ListCreatedTime < hourSeconds
  );
  const payment =
    order?.orders?.data?.length >= 1
      ? order?.orders?.data
          ?.map((x) => parseInt(x.OrderAmount))
          .reduce((a, b) => a + b)
      : 0;
  useEffect(() => {
    dispatch(getOffer());
    dispatch(getList());
    dispatch(getActiveList());
    dispatch(getUser());
    dispatch(getOrders());
  }, [dispatch]);
  return (
    <>
      <div className="dashboard">
        <Header />
        <div className="content-page">
          {user?.users && list?.lists?.data && offer?.offers?.data ? (
            <div className="dashboard-states">
              <Container>
                <Row>
                  <Col md={4}>
                    <States
                      statename={"Total Users"}
                      stateLength={
                        user?.users?.length
                          ? user?.users?.filter((x) => x.isActive === true)
                              .length - 1
                          : 0
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <States
                      statename={"Total Payment"}
                      stateLength={`
                        $${payment}`}
                    />
                  </Col>
                  <Col md={4}>
                    <States
                      statename={"Total Orders"}
                      stateLength={
                        order?.orders?.data?.length
                          ? order?.orders?.data?.length
                          : 0
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <States
                      statename={"Total Lists"}
                      stateLength={
                        list?.lists?.data?.length
                          ? list?.lists?.data?.filter(
                              (x) => x.isActive === true
                            )?.length
                          : 0
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <States
                      statename={"Active Lists"}
                      stateLength={
                        currentlyActiveList?.length
                          ? currentlyActiveList?.filter(
                              (x) => x.isActive === true
                            ).length
                          : 0
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <States
                      statename={"Total Offers"}
                      stateLength={
                        offer?.offers?.data?.length
                          ? offer?.offers?.data?.filter(
                              (x) => x.isActive === true
                            )?.length
                          : 0
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};
export default Home;
