import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  faUser,
  faHistory,
  faClipboardList,
  faTachometerAlt,
  faCog,
  faSignOutAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout } from "../../../../redux/_actions/authAction";
import "./index.css";

const SideBar = (props) => {
  const dispatch = useDispatch();
  const userDetail = localStorage.getItem("user_cred");
  const userData = JSON.parse(localStorage.getItem("user_auth"));
  const handleHide = () => {
    props.HandleSidebarChange();
  };
  function Logout() {
    dispatch(logout());
  }
  return (
    <aside className="sideBar">
      <button className="close-button" onClick={handleHide}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      {userDetail && (
        <div className="user-detail">
          <div className="user-thumbnail">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <span>
            Welcome to Ticket Text<p>{userData.firstName}</p>
          </span>
        </div>
      )}
      {!userDetail ? (
        <ul className="authenticate-link">
          <li>
            <Link to="/login">Login</Link>
          </li>
          <li>
            <Link to="/signup">Signup</Link>
          </li>
        </ul>
      ) : (
        <>
          {userData.role === "admin" ? (
            <ul className="nav-links">
              <li>
                <Link to="/admin/dashboard">
                  <FontAwesomeIcon icon={faTachometerAlt} />
                  Dashboard
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="nav-links">
              <li>
                <Link to="/mylist">
                  <FontAwesomeIcon icon={faClipboardList} />
                  My Lists
                </Link>
              </li>
              <li>
                <Link to="/orderhistory">
                  <FontAwesomeIcon icon={faHistory} />
                  Order History
                </Link>
              </li>
            </ul>
          )}
          <ul className="nav-links">
            <li>
              <Link to="/accountsetting">
                <FontAwesomeIcon icon={faCog} />
                Account Setting
              </Link>
            </li>
            <li>
              <span onClick={(e) => Logout()}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                Logout
              </span>
            </li>
          </ul>
        </>
      )}
    </aside>
  );
};
export default SideBar;
