import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { updateList } from "../../../../redux/_actions/listAction";
const UpdateList = (props) => {
  const dispatch = useDispatch();
  const listId = props.match.params.token;
  const listdata = JSON.parse(localStorage.getItem("update_list"));
  const [newList, setNewList] = useState({
    ListName: listdata?.ListName,
    ListUniqueCode: listdata?.ListUniqueCode,
  });

  const { ListUniqueCode, ListName } = newList;
  const onChange = (e) =>
    setNewList({ ...newList, [e.target.name]: e.target.value });
  const onCodeChange = (e) =>
    setNewList({ ...newList, [e.target.name]: e.target.value.toUpperCase() });
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(updateList(newList, listId));
  };
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Update List"} />
        <div className="dashboard-data-wrapper">
          <div className="dashboard-form dashboard-readonly">
            {listdata ? (
              <form className="dashboard-form" onSubmit={onSubmit}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <label>Unique Code</label>
                      <input
                        type="text"
                        maxLength={3}
                        required
                        name="ListUniqueCode"
                        value={ListUniqueCode}
                        onChange={onCodeChange}
                        placeholder="Enter Unique Code"
                      />
                    </Col>
                    <Col md={12}>
                      <label>list Name</label>
                      <input
                        type="text"
                        required
                        name="ListName"
                        value={ListName}
                        onChange={onChange}
                        placeholder="Enter list Name"
                      />
                    </Col>
                    <Col md={12} className="text-right pt-4">
                      <button>Update List</button>
                    </Col>
                  </Row>
                </Container>
              </form>
            ) : (
              <h4 className="no-data-found">NO DATA FOUND</h4>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default UpdateList;
