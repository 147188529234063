import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../../redux/_actions/authAction";
import { setAlert } from "../../../../redux/_actions/alertAction";
import { SET_ALERT } from "../../../../redux/types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../assets/logo.png";
import "./index.css";
const ChangePassword = (props) => {
  const userid = props.match.params.id;
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [Error, setError] = useState({
    oldPasswordError: "",
    passwordError: "",
    confirmPasswordError: "",
  });
  const { oldPassword, password, confirmPassword } = newPassword;
  const onChange = (e) => {
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
    setError({ ...Error, [e.target.name + "Error"]: "false" });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (oldPassword === "") {
      setError({ ...Error, oldPasswordError: "true" });
    } else if (password === "") {
      setError({ ...Error, passwordError: "true" });
    } else if (confirmPassword === "") {
      setError({ ...Error, confirmPasswordError: "true" });
    } else if (password.length < 8) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Password should be greater than 8 characters.",
          alertType: "danger",
        })
      );
    } else if (password !== confirmPassword) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Password and confirm password not matched.",
          alertType: "danger",
        })
      );
    } else {
      dispatch(changePassword(password, oldPassword, userid));
    }
  };
  return (
    <>
      <div className="changepassword-wrapper">
        <form className="changepassword" onSubmit={onSubmit}>
          <div className="logo-box">
            <img className="w-100" src={Logo} alt="" />
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <label>Old Password</label>
                <input
                  type="password"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={onChange}
                  placeholder="Enter old Password Here"
                />
                {Error.oldPasswordError === "true" && (
                  <span className="errormessage ">
                    Old Password is required
                  </span>
                )}
              </Col>
              <Col md={12}>
                <label>New Password</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Enter New Password Here"
                />
                {Error.passwordError === "true" && (
                  <span className="errormessage ">Password is required</span>
                )}
              </Col>
              <Col md={12}>
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChange}
                  placeholder="Confirm New Password"
                />
                {Error.confirmPasswordError === "true" && (
                  <span className="errormessage ">
                    Confirm Password is required
                  </span>
                )}
              </Col>
              <Col md={12}>
                <button>Change Password</button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  );
};
export default ChangePassword;
