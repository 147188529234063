import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Table from "react-bootstrap/Table";
import ConfirmMessage from "../../../confirmMessage";
import { faTrash, faEdit, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getList,
  deletList,
  activateList,
} from "../../../../redux/_actions/listAction";
import Loader from "../../../loader";
import "./index.css";
const Packages = () => {
  const dispatch = useDispatch();
  const [listDelId, setListDelId] = useState("");
  const [listActivateId, setListActivateId] = useState("");
  const [listDeletePreviewShown, setDeleteListPreviewShown] = useState(false);
  const [listActivatePreviewShown, setActivateListPreviewShown] =
    useState(false);
  const list = useSelector((state) => state.list);
  const listDeletePreview = (row) => {
    setListDelId(row._id);
    setDeleteListPreviewShown(!listDeletePreviewShown);
  };
  const ListDelete = () => {
    dispatch(deletList(listDelId));
  };
  const listDeletePreviewToggle = () => {
    setDeleteListPreviewShown(!listDeletePreviewShown);
  };
  const listActivatePreview = (row) => {
    setListActivateId(row._id);
    setActivateListPreviewShown(!listActivatePreviewShown);
  };
  const ListActivate = () => {
    dispatch(activateList(listActivateId));
  };
  const listActivatePreviewToggle = () => {
    setActivateListPreviewShown(!listActivatePreviewShown);
  };
  function Edit(data) {
    localStorage.setItem("update_list", JSON.stringify(data));
    window.location.replace(`/admin/updatelist/${data._id}`);
  }
  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Lists"} />
        {list?.lists?.data ? (
          <>
            <div className="add-section">
              <Link to="/admin/addlists">Add Lists</Link>
            </div>
            <div className="dashboard-data-wrapper">
              <Table striped bordered hover className="dashboard-data">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Unique Code</th>
                    <th>List Name</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                {list?.lists?.data?.length > 0 ? (
                  <tbody>
                    {list?.lists?.data?.map((row, i) => (
                      <tr key={i}>
                        <td>{(i = i + 1)}</td>
                        <td>{row?.ListUniqueCode}</td>
                        <td>{row?.ListName}</td>
                        <td className="text-center">
                          <span className="edit" onClick={(e) => Edit(row)}>
                            <FontAwesomeIcon icon={faEdit} />
                          </span>
                        </td>
                        <td className="text-center">
                          {row?.isActive === true ? (
                            <span onClick={() => listDeletePreview(row)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </span>
                          ) : (
                            <span
                              className="activate"
                              onClick={() => listActivatePreview(row)}
                            >
                              <FontAwesomeIcon icon={faToggleOn} />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <th colSpan="5" className="text-center py-5 bg-white ">
                        NO DATA
                      </th>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      {listDeletePreviewShown && (
        <ConfirmMessage
          title="Are you sure you want to deactivate this List?"
          dispathFunction={ListDelete}
          HideFunction={listDeletePreviewToggle}
        />
      )}
      {listActivatePreviewShown && (
        <ConfirmMessage
          title="Are you sure you want to activate this List?"
          dispathFunction={ListActivate}
          HideFunction={listActivatePreviewToggle}
        />
      )}
    </section>
  );
};
export default Packages;
