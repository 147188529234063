import {
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_LOAD_SUCCESS,
  USER_LOAD_FAIL,
  USER_LOAD_CARDDETAIL_SUCCESS,
  USER_LOAD_CARDDETAIL_FAIL,
  USER_SINGLE_LOAD_SUCCESS,
  USER_SINGLE_LOAD_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_ACTIVATE_FAIL,
  USER_ACTIVATE_SUCCESS,
} from "../types";
const initState = {
  users: [],
  user: null,
  userCard: null,
  message: null,
  error: null,
  loading: true,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_CREATE_SUCCESS:
    case USER_ACTIVATE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case USER_LOAD_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
      };
    case USER_LOAD_CARDDETAIL_SUCCESS:
      return {
        ...state,
        userCard: action.payload.data,
      };
    case USER_LOAD_FAIL:
      return { ...state };
    case USER_SINGLE_LOAD_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case USER_SINGLE_LOAD_FAIL:
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    case USER_UPDATE_FAIL:
    case USER_LOAD_CARDDETAIL_FAIL:
    case USER_CREATE_FAIL:
    case USER_UPDATE_SUCCESS:
    case USER_DELETE_FAIL:
    case USER_DELETE_SUCCESS:
    case USER_ACTIVATE_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default userReducer;
