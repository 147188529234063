import React from "react";
import "./App.css";
import Route from "./routes/index";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-phone-number-input/style.css";
import "bootstrap/dist/css/bootstrap.min.css";

const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Route />
      </QueryClientProvider>
    </>
  );
}

export default App;
