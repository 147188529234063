import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Table from "react-bootstrap/Table";
import { getList } from "../../../../redux/_actions/listAction";
import { getOffer } from "../../../../redux/_actions/offerAction";
import { getActiveList } from "../../../../redux/_actions/activelistAction";
import { sendBlastMessage } from "../../../../redux/_actions/smsServiceAction";
import Loader from "../../../loader";
import ConfirmMessage from "../../../confirmMessage";
import dateFormat from "dateformat";
import "./index.css";
const ActiveList = () => {
  const dispatch = useDispatch();
  const [resendSmsPreviewShown, setResendSmsPreviewShown] = useState(false);
  const [offerDetail, setOfferDetail] = useState("");
  const [listDetail, setListDetail] = useState("");
  const currentTime = new Date().getTime();
  const hourSeconds = 7200000;
  const list = useSelector((state) => state.list);
  const offer = useSelector((state) => state.offer);
  const activeList = useSelector((state) => state.activelist);
  const activatedLists = activeList?.activelists?.data?.filter(
    (x) => x.isActive === true
  );
  const currentlyActiveList = activatedLists?.filter(
    (x) => currentTime - x.ListCreatedTime < hourSeconds
  );
  function timeConvert(time) {
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? "AM" : "PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    } else {
      return "";
    }
  }
  const ResendSmsPreview = (e, row) => {
    setListDetail(list?.lists?.data?.find((x) => x._id === row?.ListID));
    setOfferDetail(offer?.offers?.data?.find((x) => x._id === row?.OfferId));
    setResendSmsPreviewShown(!resendSmsPreviewShown);
  };
  const ResendSmsToggle = () => {
    setResendSmsPreviewShown(!resendSmsPreviewShown);
  };
  const ResendSms = () => {
    const OfferDate = dateFormat(offerDetail?.OfferDate, "mmmm dS, yyyy");
    const message = `TICKET TEXT OFFER: \n${
      offerDetail ? offerDetail?.OfferEvent : "Event Name"
    } ${offerDetail ? OfferDate : "[EVENT DATE]"} @ ${
      offerDetail ? timeConvert(offerDetail?.OfferTime) : "[TIME]"
    }\nReply YES ${
      listDetail ? listDetail.ListUniqueCode : "[List Code]"
    } [# of tickets]`;
    dispatch(sendBlastMessage(listDetail?.listUsers, message, "yes"));
  };
  useEffect(() => {
    dispatch(getList());
    dispatch(getOffer());
    dispatch(getActiveList());
  }, [dispatch]);
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Active Lists"} />
        {activeList?.activelists?.data ? (
          <>
            <div className="dashboard-data-wrapper pt-5">
              <Table striped bordered hover className="dashboard-data">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Unique Code</th>
                    <th>List Name</th>
                    <th>Offer Name</th>
                    <th>List Status</th>
                    <th></th>
                  </tr>
                </thead>
                {currentlyActiveList?.length > 0 ? (
                  <tbody>
                    {currentlyActiveList?.map((row, i) => (
                      <tr key={i}>
                        <td>{(i = i + 1)}</td>
                        <td>{row?.ListCode}</td>
                        <td>
                          {
                            list?.lists?.data?.find(
                              (x) => x._id === row?.ListID
                            )?.ListName
                          }
                        </td>
                        <td>
                          {
                            offer?.offers?.data?.find(
                              (x) => x._id === row?.OfferId
                            )?.OfferName
                          }
                        </td>
                        <td>Active</td>
                        <td>
                          <span
                            className="resend-sms"
                            onClick={(e) => ResendSmsPreview(e, row)}
                          >
                            Resend Sms
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <th colSpan="5" className="text-center py-5 bg-white ">
                        NO DATA
                      </th>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </>
        ) : (
          <Loader />
        )}{" "}
        {resendSmsPreviewShown && (
          <ConfirmMessage
            title="Are you sure you want to Resend Blast Sms?"
            dispathFunction={ResendSms}
            HideFunction={ResendSmsToggle}
          />
        )}
      </div>
    </section>
  );
};
export default ActiveList;
