import {
  ACTIVELIST_SUCCESS,
  ACTIVELIST_FAIL,
  ACTIVELIST_LOAD_SUCCESS,
  ACTIVELIST_LOAD_FAIL,
  CLEAR_ERRORS,
} from "../types";
const initState = {
  activelists: [],
  message: null,
};

const activeListReducer = (state = initState, action) => {
  switch (action.type) {
    case ACTIVELIST_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case ACTIVELIST_FAIL:
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case ACTIVELIST_LOAD_SUCCESS:
      return {
        ...state,
        activelists: action.payload,
      };
    case ACTIVELIST_LOAD_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default activeListReducer;
