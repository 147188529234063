import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";
const BreadCrumb = (props) => {
  return (
    <section className="dashboard-breadcrumb">
      <Container>
        <Row>
          <Col md={7}>
            <h1>{props.currentPage}</h1>
          </Col>
          <Col md={5}>
            <ul>
              <li>
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </li>
              <li>
                <span>{props.currentPage}</span>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default BreadCrumb;
