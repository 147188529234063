import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Table from "react-bootstrap/Table";
import ConfirmMessage from "../../../confirmMessage";
import { faTrash, faEdit, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getOffer,
  deleteOffer,
  activateOffer,
} from "../../../../redux/_actions/offerAction";
import { getTicket } from "../../../../redux/_actions/ticketAction";
import Loader from "../../../loader";
import dateFormat from "dateformat";
import "./index.css";
const Offers = () => {
  const dispatch = useDispatch();
  const [offerDelId, setOfferDelId] = useState("");
  const [offerActivateId, setOfferActivateId] = useState("");
  const [offerDeletePreviewShown, setDeleteOfferPreviewShown] = useState(false);
  const [offerActivatePreviewShown, setActivateOfferPreviewShown] =
    useState(false);
  const offer = useSelector((state) => state.offer);
  const ticket = useSelector((state) => state.ticket);
  const offerDeletePreview = (row) => {
    setOfferDelId(row._id);
    setDeleteOfferPreviewShown(!offerDeletePreviewShown);
  };
  const offerDelete = () => {
    dispatch(deleteOffer(offerDelId));
  };
  const offerDeletePreviewToggle = () => {
    setDeleteOfferPreviewShown(!offerDeletePreviewShown);
  };
  const offerActivatePreview = (row) => {
    setOfferActivateId(row._id);
    setActivateOfferPreviewShown(!offerActivatePreviewShown);
  };
  const offerActivate = () => {
    dispatch(activateOffer(offerActivateId));
  };
  const offerActivatePreviewToggle = () => {
    setActivateOfferPreviewShown(!offerActivatePreviewShown);
  };
  function Edit(data) {
    localStorage.setItem("update_offer", JSON.stringify(data));
    window.location.replace(`/admin/updateoffer/${data._id}`);
  }
  function timeConvert(time) {
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? "AM" : "PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    } else {
      return "";
    }
  }
  useEffect(() => {
    dispatch(getOffer());
    dispatch(getTicket());
  }, [dispatch]);
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Offers"} />
        {offer?.offers?.data ? (
          <>
            <div className="add-section">
              <Link to="/admin/addoffer">Add Offer</Link>
            </div>
            <div className="dashboard-data-wrapper offer-deta-wrapper">
              <Table striped bordered hover className="dashboard-data">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Offer Name</th>
                    <th>Per Ticket Price</th>
                    <th>Ticket Quantity</th>
                    <th>Event Name</th>
                    <th>Event Time</th>
                    <th>Event Date</th>
                    <th>Uploaded Tickets</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>

                {offer?.offers?.data?.length > 0 ? (
                  <tbody>
                    {offer?.offers?.data?.map((row, i) => (
                      <tr key={i}>
                        <td>{(i = i + 1)}</td>
                        <td>{row?.OfferName}</td>
                        <td>${row?.OfferPrice}</td>
                        <td>{row?.OfferQuantity}</td>
                        <td>{row?.OfferEvent}</td>
                        <td>{timeConvert(row?.OfferTime)}</td>
                        <td>{dateFormat(row?.OfferDate, "mmmm dS, yyyy")}</td>
                        <td>
                          {
                            ticket?.tickets?.data?.filter(
                              (x) => x.OfferId === row?._id
                            ).length
                          }
                          / {row?.OfferQuantity}
                        </td>
                        <td className="text-center">
                          <span className="edit" onClick={(e) => Edit(row)}>
                            <FontAwesomeIcon icon={faEdit} />
                          </span>
                        </td>
                        <td className="text-center">
                          {row?.isActive === true ? (
                            <span onClick={() => offerDeletePreview(row)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </span>
                          ) : (
                            <span
                              className="activate"
                              onClick={() => offerActivatePreview(row)}
                            >
                              <FontAwesomeIcon icon={faToggleOn} />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <th colSpan={12} className="text-center py-5 bg-white ">
                        NO OFFER UPLOADED
                      </th>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </>
        ) : (
          <Loader />
        )}
        {offerDeletePreviewShown && (
          <ConfirmMessage
            title="Are you sure you want to deactivate this Offer?"
            dispathFunction={offerDelete}
            HideFunction={offerDeletePreviewToggle}
          />
        )}
        {offerActivatePreviewShown && (
          <ConfirmMessage
            title="Are you sure you want to activate this Offer?"
            dispathFunction={offerActivate}
            HideFunction={offerActivatePreviewToggle}
          />
        )}
      </div>
    </section>
  );
};
export default Offers;
