import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../redux/_actions/alertAction";
import { SET_ALERT } from "../../../../redux/types";
import { updateUser } from "../../../../redux/_actions/paymentAction";
import { Link } from "react-router-dom";
import CreditCardInput from "react-credit-card-input";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loader from "../../../loader/index";
import Logo from "../../../../assets/logo.png";
import { getUserCardDetail } from "../../../../redux/_actions/userAction";
import "./index.css";
const UpdateCard = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("user_auth"));
  const userCard = useSelector((state) => state.user.userCard);
  const [newCard, setnewCard] = useState({
    userId: userData._id,
    email: userData.email,
    firstName: userData.firstName,
    phoneNumber: userData.phoneNumber,
    cardZip: userData.cardZip,
    billingAddress: userData.billingAddress,
    billingCity: userData.billingCity,
    billingState: userData.billingState,
  });
  const [Error, setError] = useState({
    cardNumberError: "",
    cardExpiryYearError: "",
    cardCVVError: "",
  });
  const { cardNumber, cardExpiryYear, cardCVV } = newCard;
  const onChange = (e) => {
    setnewCard({ ...newCard, [e.target.name]: e.target.value });
    setError({ ...Error, [e.target.name + "Error"]: "false" });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (cardNumber === "") {
      setError({ ...Error, cardNumberError: "true" });
    } else if (cardExpiryYear === "") {
      setError({ ...Error, cardExpiryYearError: "true" });
    } else if (cardCVV === "") {
      setError({ ...Error, cardCVVError: "true" });
    } else if (cardExpiryYear.length < 5) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Invalid Expiry Detail.",
          alertType: "danger",
        })
      );
    } else {
      if (newCard.cardExpiryYear.length > 5) {
        let CardArray = newCard.cardExpiryYear.split("/");
        newCard.cardExpiryMonth = CardArray[0].replace(/\s/g, "");
        newCard.cardExpiryYear = CardArray[1].replace(/\s/g, "");
      }
      dispatch(updateUser(newCard));
    }
  };
  useEffect(() => {
    dispatch(getUserCardDetail(userData.cardId));
  }, [dispatch]);
  return (
    <>
      {userCard ? (
        <div className="changecard-wrapper">
          <form className="changecard" onSubmit={onSubmit}>
            <div className="logo-box">
              <img className="w-100" src={Logo} alt="" />
            </div>
            <Container>
              <Row>
                <Col md={12} className="card-detail">
                  <CreditCardInput
                    cardNumberInputProps={{
                      name: "cardNumber",
                      value: cardNumber,
                      placeholder: `--------${userCard?.last4}`,
                      onChange: onChange,
                    }}
                    cardExpiryInputProps={{
                      name: "cardExpiryYear",
                      value: cardExpiryYear,
                      onChange: onChange,
                    }}
                    cardCVCInputProps={{
                      name: "cardCVV",
                      value: cardCVV,
                      onChange: onChange,
                    }}
                    fieldClassName="card-input"
                  />
                  {Error.cardNumberError === "true" && (
                    <span className="errormessage ">
                      Card Number is required
                    </span>
                  )}
                  {Error.cardExpiryYearError === "true" && (
                    <span className="errormessage ">
                      Card Expiry Year is required
                    </span>
                  )}
                  {Error.cardCVVError === "true" && (
                    <span className="errormessage ">CVC is required</span>
                  )}
                </Col>
                <Col md={12}>
                  <button>Update Card</button>
                  <p>
                    <Link to="/accountsetting"> GO BACK</Link>
                  </p>
                </Col>
              </Row>
            </Container>
          </form>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default UpdateCard;
