import {
  TICKET_FAIL,
  TICKET_SUCCESS,
  TICKET_LOAD_SUCCESS,
  TICKET_LOAD_FAIL,
  TICKET_EMPTY_SET,
  TICKET_UPDATE_SUCCESS,
  TICKET_UPDATE_FAIL,
  TICKET_DELETE_FAIL,
  TICKET_DELETE_SUCCESS,
  CLEAR_ERRORS,
} from "../types";

const initState = {
  tickets: [],
  error: null,
  loading: true,
};

const ticketReducer = (state = initState, action) => {
  switch (action.type) {
    case TICKET_LOAD_SUCCESS:
      return {
        ...state,
        tickets: action.payload,
        loading: false,
      };
    case TICKET_LOAD_FAIL:
      return {
        ...state,
        tickets: [],
        error: action.payload,
      };
    case TICKET_EMPTY_SET:
      return {
        ...state,
        tickets: [],
        loading: false,
      };
    case TICKET_SUCCESS:
      return {
        ...state,
        tickets: action.payload,
        loading: false,
      };
    case TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case TICKET_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case TICKET_DELETE_FAIL:
      return { ...state, error: action.payload };
    case TICKET_DELETE_SUCCESS:
      return { ...state };
    case TICKET_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default ticketReducer;
