import {
  LIST_SUCCESS,
  LIST_FAIL,
  LIST_LOAD_SUCCESS,
  LIST_LOAD_FAIL,
  LIST_SINGLE_LOAD_SUCCESS,
  LIST_SINGLE_LOAD_FAIL,
  LIST_BLASTSMS_LOAD_SUCCESS,
  LIST_BLASTSMS_LOAD_FAIL,
  LIST_SMS_LOAD_SUCCESS,
  LIST_SMS_LOAD_FAIL,
  LIST_SUBSCRIBE_SUCCESS,
  LIST_SUBSCRIBE_FAIL,
  LIST_UNSUBSCRIBE_SUCCESS,
  LIST_UNSUBSCRIBE_FAIL,
  LIST_USER_SUBSCRIBE_LOAD_SUCCESS,
  LIST_USER_SUBSCRIBE_LOAD_FAIL,
  LIST_USER_UNSUBSCRIBE_LOAD_SUCCESS,
  LIST_USER_UNSUBSCRIBE_LOAD_FAIL,
  LIST_UPDATE_SUCCESS,
  LIST_UPDATE_FAIL,
  LIST_DELETE_SUCCESS,
  LIST_DELETE_FAIL,
  LIST_ACTIVATE_SUCCESS,
  LIST_ACTIVATE_FAIL,
  SET_ALERT,
} from "../types";
import axios from "axios";
import { setAlert } from "./alertAction";
import { sendMessage } from "./smsServiceAction";

export const getList = () => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.get(`${window.appUrl}/api/list/`, config);
      dispatch({ type: LIST_LOAD_SUCCESS, payload: res?.data });
    } catch (err) {
      dispatch({ type: LIST_LOAD_FAIL, payload: err.response?.data?.message });
    }
  };
};
export const getListById = (listId) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${window.appUrl}/api/list/` + listId,
        config
      );

      dispatch({ type: LIST_SINGLE_LOAD_SUCCESS, payload: res.data.data });
    } catch (err) {
      dispatch({
        type: LIST_SINGLE_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};
export const getBlastSmsList = (listId) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${window.appUrl}/api/list/` + listId,
        config
      );

      dispatch({ type: LIST_BLASTSMS_LOAD_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch({
        type: LIST_BLASTSMS_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};
export const getSingleSmsList = (listId) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${window.appUrl}/api/list/` + listId,
        config
      );

      dispatch({ type: LIST_SMS_LOAD_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch({
        type: LIST_SMS_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};
export const getUserSubscribedList = (id) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.get(
        `${window.appUrl}/api/list/user/subscribe/` + id,
        config
      );
      dispatch({ type: LIST_USER_SUBSCRIBE_LOAD_SUCCESS, payload: res?.data });
    } catch (err) {
      dispatch({
        type: LIST_USER_SUBSCRIBE_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};
export const getUserUnsubscribedList = (id) => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.get(
        `${window.appUrl}/api/list/user/unsubscribe/` + id,
        config
      );
      dispatch({
        type: LIST_USER_UNSUBSCRIBE_LOAD_SUCCESS,
        payload: res?.data,
      });
    } catch (err) {
      dispatch({
        type: LIST_USER_UNSUBSCRIBE_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};
export const createList = (list) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/list/insert`,
        { list },
        config
      );
      dispatch(
        setAlert(SET_ALERT, {
          message: "List Added Successfully",
          alertType: "success",
        })
      );
      setTimeout(() => {
        window.location.replace("/admin/lists");
      }, 2000);
      dispatch({ type: LIST_SUCCESS, payload: res?.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: LIST_FAIL, payload: err.response.data });
    }
  };
};
export const subscribeList = (
  listId,
  UserId,
  isActive,
  phoneNumber,
  message
) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/list/subscribe/` + listId,
        { UserId, isActive },
        config
      );
      dispatch(
        setAlert(SET_ALERT, {
          message: res.data.message,
          alertType: "success",
        })
      );
      dispatch(sendMessage(phoneNumber, message));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      dispatch({ type: LIST_SUBSCRIBE_SUCCESS, payload: res?.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: LIST_SUBSCRIBE_FAIL, payload: err.response.data });
    }
  };
};
export const unsubscribeList = (listId, UserId, phoneNumber, message) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/list/unsubscribe/` + listId,
        { UserId },
        config
      );
      dispatch(
        setAlert(SET_ALERT, {
          message: res.data.message,
          alertType: "success",
        })
      );
      dispatch(sendMessage(phoneNumber, message));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      dispatch({ type: LIST_UNSUBSCRIBE_SUCCESS, payload: res?.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: LIST_UNSUBSCRIBE_FAIL, payload: err.response.data });
    }
  };
};
export const updateList = (list, id) => async (dispatch) => {
  try {
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.patch(
      `${window.appUrl}/api/list/update/` + id,
      list,
      config
    );
    dispatch(
      setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
    );
    dispatch({ type: LIST_UPDATE_SUCCESS, payload: res.data });
    localStorage.removeItem("update_list");
    window.location.replace("/admin/lists");
  } catch (err) {
    dispatch({ type: LIST_UPDATE_FAIL, payload: err.message });
  }
};
export const deletList = (id) => async (dispatch) => {
  try {
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.delete(`${window.appUrl}/api/list/` + id, config);

    dispatch(
      setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
    );
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    dispatch({ type: LIST_DELETE_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: LIST_DELETE_FAIL, payload: err.message });
  }
};
export const activateList = (id) => async (dispatch) => {
  try {
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.patch(
      `${window.appUrl}/api/list/activatelist/` + id,
      config
    );
    dispatch(
      setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
    );
    setTimeout(() => {
      window.location.reload();
    }, 1000);

    dispatch({ type: LIST_ACTIVATE_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: LIST_ACTIVATE_FAIL, payload: err.message });
  }
};
