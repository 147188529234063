import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTicket } from "../../../../redux/_actions/ticketAction";
import "./index.css";
const TicketPreview = (props) => {
  const dispatch = useDispatch();
  const ticketId = props?.match.params.token;
  const ticket = useSelector((state) => state.ticket);
  const currentTicket = ticket?.tickets?.data?.find((x) => x._id === ticketId);
  useEffect(() => {
    dispatch(getTicket());
  }, [dispatch]);

  return (
    <>
      <iframe
        title="ticket-preview"
        src={`/upload/${currentTicket?.TicketImage}`}
        frameBorder="0"
        className="ticket-preview"
        scrolling="auto"
      ></iframe>
    </>
  );
};
export default TicketPreview;
