import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { updateUser } from "../../../../redux/_actions/userAction";
import "./index.css";
const UpdateUser = (props) => {
  const dispatch = useDispatch();
  const userId = props.match.params.token;
  const userdata = JSON.parse(localStorage.getItem("update_user"));
  const [newUser, setNewUser] = useState({
    firstName: userdata?.firstName,
    lastName: userdata?.lastName,
    email: userdata?.email,
    billingAddress: userdata?.billingAddress,
    billingCity: userdata?.billingCity,
    billingState: userdata?.billingState,
    role: userdata?.role,
    isActive: "true",
  });

  const {
    firstName,
    lastName,
    email,
    billingAddress,
    billingCity,
    billingState,
  } = newUser;
  const onChange = (e) =>
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUser(newUser, userId));
  };
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Update User"} />
        <div className="dashboard-data-wrapper">
          <div className="dashboard-form dashboard-readonly">
            {userdata ? (
              <form className="dashboard-form" onSubmit={onSubmit}>
                <Container>
                  <Row>
                    <Col md={12}>
                      <label>Email Address</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={onChange}
                        placeholder="Email Address"
                      />
                    </Col>
                    <Col md={6}>
                      <label>First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={onChange}
                        placeholder="First Name"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={lastName}
                        onChange={onChange}
                        placeholder="Last Name"
                      />
                    </Col>
                    <Col md={12}>
                      <label>Billing Address</label>
                      <input
                        type="text"
                        name="billingAddress"
                        value={billingAddress}
                        onChange={onChange}
                        placeholder="Billing Address"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Billing City</label>
                      <input
                        type="text"
                        name="billingCity"
                        value={billingCity}
                        onChange={onChange}
                        placeholder="Billing City"
                      />
                    </Col>
                    <Col md={6}>
                      <label>Billing State</label>
                      <input
                        type="text"
                        name="billingState"
                        value={billingState}
                        onChange={onChange}
                        placeholder="Billing State"
                      />
                    </Col>
                    <Col md={12} className="text-right mt-4">
                      <button>Update User</button>
                    </Col>
                  </Row>
                </Container>
              </form>
            ) : (
              <h4 className="no-data-found">NO DATA FOUND</h4>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default UpdateUser;
