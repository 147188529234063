import { combineReducers } from "redux";
import alertReducer from "./alertReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import listReducer from "./listReducer";
import offerReducer from "./offerReducer";
import ticketReducer from "./ticketReducer";
import activeListReducer from "./activeListReducer";
import orderReducer from "./orderReducer";
import paymentReducer from "./paymentReducer";

export default combineReducers({
  alert: alertReducer,
  auth: authReducer,
  user: userReducer,
  list: listReducer,
  offer: offerReducer,
  ticket: ticketReducer,
  activelist: activeListReducer,
  order: orderReducer,
  payment: paymentReducer,
});
