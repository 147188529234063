import {
  TICKET_FAIL,
  TICKET_SUCCESS,
  TICKET_LOAD_SUCCESS,
  TICKET_LOAD_FAIL,
  TICKET_UPDATE_SUCCESS,
  TICKET_UPDATE_FAIL,
  TICKET_DELETE_FAIL,
  TICKET_DELETE_SUCCESS,
  SET_ALERT,
} from "../types";
import { setAlert } from "./alertAction";
import axios from "axios";

export const addTicket = (
  TicketID,
  TicketImage,
  TicketDescription,
  OfferId
) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/ticket/insert`,
        { TicketID, TicketImage, TicketDescription, OfferId },
        config
      );
      dispatch(
        setAlert(SET_ALERT, {
          message: "Ticket Added Successfully",
          alertType: "success",
        })
      );
      window.location.reload();
      dispatch({ type: TICKET_SUCCESS, payload: res?.data?.data });
    } catch (err) {
      dispatch({ type: TICKET_FAIL, payload: err.response?.data?.message });
    }
  };
};

export const getTicket = () => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(`${window.appUrl}/api/ticket/`, config);

      dispatch({ type: TICKET_LOAD_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch({
        type: TICKET_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};

export const updateTicket = (ticket, ticketId) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "multipart/form-data" } };
    try {
      const res = await axios.patch(
        `${window.appUrl}/api/ticket/` + ticketId,
        ticket,
        config
      );

      dispatch({ type: TICKET_UPDATE_SUCCESS, payload: res?.data });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: TICKET_UPDATE_FAIL,
        payload: err?.response?.data?.message,
      });
    }
  };
};

export const deleteTicket = (id) => async (dispatch) => {
  const config = { header: { "Content-Type": "multipart/form-data" } };
  try {
    const res = await axios.delete(
      `${window.appUrl}/api/ticket/` + id,
      { id },
      config
    );

    dispatch({ type: TICKET_DELETE_SUCCESS, payload: res.data });
    window.location.reload();
  } catch (err) {
    dispatch({
      type: TICKET_DELETE_FAIL,
      payload: err?.response?.data?.message,
    });
  }
};
