import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../../../redux/_actions/authAction";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../assets/logo.png";
import "./index.css";
const Login = () => {
  const dispatch = useDispatch();
  const [loginUser, setloginUser] = useState({
    phoneemail: "",
    password: "",
  });
  const [Error, setError] = useState({
    phoneemailError: "",
    passwordError: "",
  });
  const [FormatError, setFormatError] = useState("");
  const [phoneFormatError, setPhoneFormatError] = useState("");
  const { phoneemail, password } = loginUser;
  const emailOnChange = (e) => {
    setloginUser({
      ...loginUser,
      [e.target.name]: e.target.value.toString().toLowerCase(),
    });
    setError({ ...Error, [e.target.name + "Error"]: "false" });
    setFormatError("false");
    setPhoneFormatError("false");
  };
  const onChange = (e) => {
    setloginUser({ ...loginUser, [e.target.name]: e.target.value });
    setError({ ...Error, [e.target.name + "Error"]: "false" });
    setFormatError("false");
    setPhoneFormatError("false");
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (phoneemail === "") {
      setError({ ...Error, phoneemailError: "true" });
    } else if (password === "") {
      setError({ ...Error, passwordError: "true" });
    } else {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(phoneemail)) {
        dispatch(login(loginUser));
      } else if (!isNaN(phoneemail)) {
        if (phoneemail.charAt(0) != "+") {
          setPhoneFormatError("true");
        } else {
          dispatch(login(loginUser));
        }
      } else {
        setFormatError("true");
      }
    }
  };
  return (
    <>
      <div className="login-wrapper">
        <form className="login" onSubmit={onSubmit}>
          <div className="logo-box">
            <img className="w-100" src={Logo} alt="" />
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <label>Email Address / Phone Number</label>
                <input
                  type="text"
                  name="phoneemail"
                  value={phoneemail}
                  onChange={emailOnChange}
                  placeholder="Email Or Phone"
                />
                {Error.phoneemailError === "true" && (
                  <span className="errormessage ">
                    Email Address / Phone Number is required
                  </span>
                )}
                {FormatError === "true" && (
                  <span className="errormessage ">Format is not correct</span>
                )}
                {phoneFormatError === "true" && (
                  <span className="errormessage ">
                    Country Code is required eg +1XXXXXXXX
                  </span>
                )}
              </Col>
              <Col md={12}>
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Password"
                />
                {Error.passwordError === "true" && (
                  <span className="errormessage ">Password is required</span>
                )}
              </Col>
              <Col md={12} className="text-left">
                <Link to="/forgetpassword">Forget Password?</Link>
              </Col>
              <Col md={12}>
                <button>Login</button>
                <p>
                  Haven't account yet?
                  <Link to="/signup">Register</Link>
                </p>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  );
};
export default Login;
