import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Loader from "../../../loader";
import Table from "react-bootstrap/Table";
import ConfirmMessage from "../../../confirmMessage";
import { faTrash, faEdit, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getUser,
  deleteUser,
  activateUser,
} from "../../../../redux/_actions/userAction";
import "./index.css";
const Users = () => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [userActivateId, setUserActivateId] = useState("");
  const [userDeletePreviewShown, setDeleteUserPreviewShown] = useState(false);
  const [userActivatePreviewShown, setActivateUserPreviewShown] =
    useState(false);
  const userdata = useSelector((state) => state.user.users);
  const user = userdata.filter((user) => user.role !== "admin");
  const userDeletePreview = (e, row) => {
    setUserId(row._id);
    setDeleteUserPreviewShown(!userDeletePreviewShown);
  };
  const userDelete = () => {
    dispatch(deleteUser(userId));
  };
  const userDeleteToggle = () => {
    setDeleteUserPreviewShown(!userDeletePreviewShown);
  };
  const userActivatePreview = (e, row) => {
    setUserActivateId(row._id);
    setActivateUserPreviewShown(!userActivatePreviewShown);
  };
  const userActivate = () => {
    dispatch(activateUser(userActivateId));
  };
  const userActivateToggle = () => {
    setActivateUserPreviewShown(!userActivatePreviewShown);
  };
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  function Edit(data) {
    localStorage.setItem("update_user", JSON.stringify(data));
    window.location.replace(`/admin/updateuser/${data._id}`);
  }
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Users"} />
        <div className="add-section">
          <Link to="/admin/adduser">Add User</Link>
        </div>
        {user ? (
          <div className="dashboard-data-wrapper">
            <Table striped bordered hover className="dashboard-data ">
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>User Number</th>
                  <th>User Email</th>
                  <th>Status</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              {user?.length > 0 ? (
                <tbody>
                  {user?.map((row, i) => (
                    <tr key={i}>
                      <td>{(i = i + 1)}</td>
                      <td>
                        <Link to={`/admin/userdetail/${row?._id}`}>
                          {row?.firstName}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/admin/userdetail/${row?._id}`}>
                          {row?.phoneNumber}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/admin/userdetail/${row?._id}`}>
                          {row?.email}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/admin/userdetail/${row?._id}`}>
                          {row?.isActive === true ? "Active" : "Deactivated"}
                        </Link>
                      </td>
                      <td className="text-center">
                        <span className="edit" onClick={(e) => Edit(row)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </span>
                      </td>
                      <td className="text-center">
                        {row?.isActive === true ? (
                          <span onClick={(e) => userDeletePreview(e, row)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                        ) : (
                          <span
                            className="activate"
                            onClick={(e) => userActivatePreview(e, row)}
                          >
                            <FontAwesomeIcon icon={faUserPlus} />
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <th colSpan="6" className="text-center py-5 bg-white ">
                      NO DATA
                    </th>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      {userDeletePreviewShown && (
        <ConfirmMessage
          title="Are you sure you want to deactivate this Account"
          dispathFunction={userDelete}
          HideFunction={userDeleteToggle}
        />
      )}
      {userActivatePreviewShown && (
        <ConfirmMessage
          title="Are you sure you want to activate this Account"
          dispathFunction={userActivate}
          HideFunction={userActivateToggle}
        />
      )}
    </section>
  );
};
export default Users;
