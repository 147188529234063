import {
  ORDER_LOAD_SUCCESS,
  ORDER_LOAD_FAIL,
  ORDER_SINGLE_LOAD_SUCCESS,
  ORDER_SINGLE_LOAD_FAIL,
  ORDER_USER_LOAD_SUCCESS,
  ORDER_USER_SINGLE_LOAD_FAIL,
} from "../types";
import axios from "axios";

export const getOrders = () => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.get(`${window.appUrl}/api/order/`, config);
      dispatch({ type: ORDER_LOAD_SUCCESS, payload: res?.data });
    } catch (err) {
      dispatch({ type: ORDER_LOAD_FAIL, payload: err.response?.data?.message });
    }
  };
};

export const getOrderById = (orderId) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${window.appUrl}/api/order/` + orderId,
        config
      );
      dispatch({ type: ORDER_SINGLE_LOAD_SUCCESS, payload: res.data.data });
    } catch (err) {
      dispatch({
        type: ORDER_SINGLE_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};

export const getOrderByUserId = (userId) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.get(
        `${window.appUrl}/api/order/user/` + userId,
        config
      );
      dispatch({ type: ORDER_USER_LOAD_SUCCESS, payload: res.data.data });
    } catch (err) {
      dispatch({
        type: ORDER_USER_SINGLE_LOAD_FAIL,
        payload: err.response?.data?.message,
      });
    }
  };
};
