import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useState } from "react";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useState(JSON.parse(localStorage.getItem("user_auth")));
  return (
    <Route
      {...rest}
      render={(props) =>
        user[0] && user[0].role === "admin" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
