import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/logo-icon.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SideBar from "../sideBar";
import {
  faChevronDown,
  faBars,
  faTimes,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserDetail, logout } from "../../../../redux/_actions/authAction";
import "./index.css";
const Header = (props) => {
  const dispatch = useDispatch();
  const [sideBarShow, setSideBarShow] = useState("");
  const [dropdown, setdropdown] = useState("");
  const userDetail = localStorage.getItem("user_cred");
  const userData = JSON.parse(localStorage.getItem("user_auth"));
  const HandleDropdownChange = (e) => {
    if (dropdown === "") {
      setdropdown("true");
    } else {
      setdropdown("");
    }
  };
  const HandleSidebarChange = (e) => {
    if (sideBarShow === "") {
      setSideBarShow("true");
    } else {
      setSideBarShow("");
    }
  };
  function Logout() {
    dispatch(logout());
  }
  useEffect(() => {
    if (userData) {
      dispatch(getUserDetail(userData._id));
    }
  }, [dispatch]);
  return (
    <>
      <header>
        <Container>
          <Row>
            <Col lg={6} xs={8}>
              {props.logo === "true" && (
                <div className="logoBox">
                  <Link to="/">
                    <img className="w-100" src={Logo} alt="" />
                  </Link>
                </div>
              )}
            </Col>
            <Col lg={6} xs={4}>
              <>
                {!userDetail ? (
                  <ul>
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                    <li>
                      <Link to="/signup">Signup</Link>
                    </li>
                  </ul>
                ) : (
                  <>
                    {userData.role === "admin" ? (
                      <ul className="nav-links">
                        <li>
                          <Link to="/admin/dashboard">Dashboard</Link>
                        </li>
                      </ul>
                    ) : (
                      <ul className="nav-links">
                        <li>
                          <Link to="/mylist">My Lists</Link>
                        </li>
                        <li>
                          <Link to="/orderhistory">Order History</Link>
                        </li>
                      </ul>
                    )}
                    <div className="dropdown-wrapper">
                      <div className="dropdown-button">
                        <span
                          className="dropdown-button"
                          onClick={(e) => HandleDropdownChange(e)}
                        >
                          {userData.firstName}
                          <FontAwesomeIcon
                            icon={
                              dropdown === "true" ? faChevronUp : faChevronDown
                            }
                          />
                        </span>
                        {dropdown === "true" && (
                          <div className="dropdown-content">
                            <Link to="/accountsetting">Setting</Link>
                            <span onClick={(e) => Logout()}>Logout</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <button
                  className="sidebar-toggle"
                  onClick={(e) => HandleSidebarChange(e)}
                >
                  {sideBarShow === "true" ? (
                    <FontAwesomeIcon icon={faTimes} />
                  ) : (
                    <FontAwesomeIcon icon={faBars} />
                  )}
                </button>
              </>
            </Col>
          </Row>
        </Container>
      </header>
      {sideBarShow === "true" && (
        <SideBar HandleSidebarChange={HandleSidebarChange} />
      )}
    </>
  );
};
export default Header;
