import {
  PAYMENT_DETAIL_SUCCESS,
  PAYMENT_DETAIL_FAIL,
  PAYMENT_DETAIL_ADMIN_SUCCESS,
  PAYMENT_DETAIL_ADMIN_FAIL,
  PAYMENT_UPDATE_CUSTOMER_SUCCESS,
  PAYMENT_UPDATE_CUSTOMER_FAIL,
  CLEAR_ERRORS,
} from "../types";
const initState = {
  message: null,
};

const paymentReducer = (state = initState, action) => {
  switch (action.type) {
    case PAYMENT_DETAIL_SUCCESS:
    case PAYMENT_DETAIL_ADMIN_SUCCESS:
    case PAYMENT_UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case PAYMENT_DETAIL_FAIL:
    case PAYMENT_DETAIL_ADMIN_FAIL:
    case PAYMENT_UPDATE_CUSTOMER_FAIL:
    case CLEAR_ERRORS:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default paymentReducer;
