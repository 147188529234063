import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../partails/pageTitle";
import Header from "../../partails/header";
import Table from "react-bootstrap/Table";
import Loader from "../../../loader";
import { getOrderByUserId } from "../../../../redux/_actions/orderAction";
import { getList } from "../../../../redux/_actions/listAction";
import { getOffer } from "../../../../redux/_actions/offerAction";
import OrderDetail from "../../partails/orderDetail";
import dateFormat from "dateformat";
import "./index.css";
const OrderHistory = () => {
  const dispatch = useDispatch();
  const [ticketPreview, setTicketPreview] = useState(false);
  const [tickets, setTickets] = useState(0);
  const user = useState(JSON.parse(localStorage.getItem("user_auth")));
  const order = useSelector((state) => state.order);
  const list = useSelector((state) => state.list);
  const offer = useSelector((state) => state.offer);
  const ticketDetailToggle = () => {
    setTicketPreview(!ticketPreview);
  };
  const ticketDetailPreviewToggle = (item) => {
    setTicketPreview(!ticketPreview);
    setTickets(item.tickets);
  };
  function timeConvert(time) {
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? "AM" : "PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    } else {
      return "";
    }
  }
  function milliSecondsToTime(duration) {
    if (duration) {
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
        format = "";
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      format = hours < 12 ? "AM" : "PM";
      return hours + ":" + minutes + ":" + seconds + " " + format;
    } else {
      return "";
    }
  }
  useEffect(() => {
    dispatch(getOrderByUserId(user[0]._id));
    dispatch(getList());
    dispatch(getOffer());
  }, [dispatch]);
  return (
    <>
      <Header logo={"true"} />
      <PageTitle PageTitle={"Order History"} />
      {order?.userorder ? (
        <div className="order-history">
          {order?.userorder?.length >= 1 ? (
            <div className="order-data-wrapper">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Order Date</th>
                    <th>Order Time</th>
                    <th>Event Name</th>
                    <th>Event Date</th>
                    <th>Event Time</th>
                    <th>Event Location</th>
                    <th>List Name</th>
                    <th>Total Tickets</th>
                    <th>Order Amount</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {order?.userorder?.map((row, i) => (
                    <tr key={i}>
                      <td>{(i = i + 1)}</td>
                      <td>{dateFormat(row?.createdAt, "mmmm dS, yyyy")}</td>
                      <td>{milliSecondsToTime(row?.createdTime)}</td>
                      <td>
                        {
                          offer?.offers?.data?.find(
                            (x) => x._id === row?.OfferId
                          )?.OfferEvent
                        }
                      </td>
                      <td>
                        {dateFormat(
                          offer?.offers?.data?.find(
                            (x) => x._id === row?.OfferId
                          )?.OfferDate,
                          "mmmm dS, yyyy"
                        )}
                      </td>
                      <td>
                        {timeConvert(
                          offer?.offers?.data?.find(
                            (x) => x._id === row?.OfferId
                          )?.OfferTime
                        )}
                      </td>
                      <td>
                        {
                          offer?.offers?.data?.find(
                            (x) => x._id === row?.OfferId
                          )?.OfferLocation
                        }
                      </td>
                      <td>
                        {
                          list?.lists.data?.find((x) => x._id === row?.ListId)
                            ?.ListName
                        }
                      </td>
                      <td>{row?.tickets?.length}</td>
                      <td>${row?.OrderAmount}</td>
                      <td>Delivered</td>
                      <td>
                        <span onClick={() => ticketDetailPreviewToggle(row)}>
                          Preview Tickets
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <h1 className="no-data-found">FEED OF ORDER HISTORY</h1>
          )}
        </div>
      ) : (
        <Loader />
      )}
      {ticketPreview && (
        <OrderDetail
          tickets={tickets}
          ticketDetailToggle={ticketDetailToggle}
        />
      )}
    </>
  );
};

export default OrderHistory;
