import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { verifyCode } from "../../../../redux/_actions/smsServiceAction";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../assets/logo.png";
import "./index.css";
const VerifyCode = () => {
  const userr = JSON.parse(localStorage.getItem("user_detail"));
  // const userNumber = userr.phoneNumber;
  const dispatch = useDispatch();
  const [newCode, setnewCode] = useState({
    phoneNumber: userr?.phoneNumber,
    verifycode: "",
  });
  const onChange = (e) =>
    setnewCode({ ...newCode, [e.target.name]: e.target.value });
  const { verifycode } = newCode;
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(verifyCode(newCode));
  };
  const [resendCode, setResendCode] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [minutes, setMinutes] = useState(1);
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    if (seconds === 1) {
      if (minutes === 0) {
        setSeconds(0);
        setResendCode(1);
      } else {
        setMinutes(minutes - 1);
        setSeconds(60);
      }
    }
  }, [seconds, minutes]);
  return (
    <>
      <div className="verifycode-wrapper">
        <form className="verifycode" onSubmit={onSubmit}>
          <div className="logo-box">
            <img className="w-100" src={Logo} alt="" />
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <input
                  type="number"
                  min={0}
                  name="verifycode"
                  value={verifycode}
                  onChange={onChange}
                  placeholder="Enter Code Here"
                />
              </Col>
              <Col md={12}>
                <h6>
                  Haven't Receive code yet?
                  {resendCode === 0 ? (
                    <span>
                      0{minutes}:{seconds < 10 && 0}
                      {seconds}
                    </span>
                  ) : (
                    <span>Resend Code</span>
                  )}
                </h6>
              </Col>
              <Col md={12}>
                <button>Verify Number</button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  );
};
export default VerifyCode;
