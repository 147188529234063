import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../partials/header/index";
import BreadCrumb from "../../partials/breadcrumb";
import Table from "react-bootstrap/Table";
import { getOrders } from "../../../../redux/_actions/orderAction";
import { getList } from "../../../../redux/_actions/listAction";
import { getOffer } from "../../../../redux/_actions/offerAction";
import { getUser } from "../../../../redux/_actions/userAction";
import OrderDetail from "../../partials/orderDetail";
import dateFormat from "dateformat";
import Loader from "../../../loader";
import "./index.css";
const Orders = () => {
  const dispatch = useDispatch();
  const [ticketPreview, setTicketPreview] = useState(false);
  const [tickets, setTickets] = useState(0);
  const order = useSelector((state) => state.order);
  const list = useSelector((state) => state.list);
  const offer = useSelector((state) => state.offer);
  const user = useSelector((state) => state.user.users);
  function timeConvert(time) {
    if (time) {
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? "AM" : "PM";
        time[0] = +time[0] % 12 || 12;
      }
      return time.join("");
    } else {
      return "";
    }
  }
  function milliSecondsToTime(duration) {
    if (duration) {
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
        format = "";
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      format = hours < 12 ? "AM" : "PM";
      return hours + ":" + minutes + ":" + seconds + " " + format;
    } else {
      return "";
    }
  }
  const ticketDetailToggle = () => {
    setTicketPreview(!ticketPreview);
  };
  const ticketDetailPreviewToggle = (item) => {
    setTicketPreview(!ticketPreview);
    setTickets(item.tickets);
  };
  useEffect(() => {
    dispatch(getOrders());
    dispatch(getOffer());
    dispatch(getList());
    dispatch(getUser());
  }, [dispatch]);
  return (
    <section className="dashboard">
      <Header />
      <div className="content-page">
        <BreadCrumb currentPage={"Orders"} />
        {order?.orders?.data ? (
          <>
            <div className="dashboard-data-wrapper order-detail-data-wrapper pt-4">
              <Table striped bordered hover className="dashboard-data">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Customer Name</th>
                    <th>Event Name</th>
                    <th>Event Date</th>
                    <th>Event Time</th>
                    <th>Event Location</th>
                    <th>List Name</th>
                    <th>Total Tickets</th>
                    <th>Order Amount</th>
                    <th>Order Date</th>
                    <th>Order Time</th>
                    <th>Order Status</th>
                    <th>Payment Status</th>
                    <th></th>
                  </tr>
                </thead>
                {order?.orders?.data?.length > 0 ? (
                  <tbody>
                    {order?.orders?.data?.map((row, i) => (
                      <tr key={i}>
                        <td>{(i = i + 1)}</td>
                        <td>
                          <Link
                            to={`/admin/userdetail/${row?.createdBy}`}
                            target="_blank"
                          >
                            {
                              user?.find((x) => x._id === row?.createdBy)
                                ?.firstName
                            }
                          </Link>
                        </td>
                        <td>
                          {
                            offer?.offers?.data?.find(
                              (x) => x._id === row?.OfferId
                            )?.OfferEvent
                          }
                        </td>
                        <td>
                          {dateFormat(
                            offer?.offers?.data?.find(
                              (x) => x._id === row?.OfferId
                            )?.OfferDate,
                            "mmmm dS, yyyy"
                          )}
                        </td>
                        <td>
                          {timeConvert(
                            offer?.offers?.data?.find(
                              (x) => x._id === row?.OfferId
                            )?.OfferTime
                          )}
                        </td>
                        <td>
                          {
                            offer?.offers?.data?.find(
                              (x) => x._id === row?.OfferId
                            )?.OfferLocation
                          }
                        </td>
                        <td>
                          {
                            list?.lists.data?.find((x) => x._id === row?.ListId)
                              ?.ListName
                          }
                        </td>
                        <td>{row?.tickets?.length}</td>
                        <td>${row?.OrderAmount}</td>
                        <td>{dateFormat(row?.createdAt, "mmmm dS, yyyy")}</td>
                        <td>{milliSecondsToTime(row?.createdTime)}</td>
                        <td>Delivered</td>
                        <td>Recived</td>
                        <td>
                          <span onClick={() => ticketDetailPreviewToggle(row)}>
                            Preview Tickets
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <th colSpan="11" className="text-center py-5 bg-white ">
                        NO DATA
                      </th>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          </>
        ) : (
          <Loader />
        )}
        {ticketPreview && (
          <OrderDetail
            tickets={tickets}
            ticketDetailToggle={ticketDetailToggle}
          />
        )}
      </div>
    </section>
  );
};
export default Orders;
