import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageTitle = (props) => {
  return (
    <div className="page-title">
      <h1>{props.PageTitle}</h1>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {props.AnotherLink && (
          <>
            <li>
              <FontAwesomeIcon icon={faLongArrowAltRight} />
            </li>
            <li>
              <Link to={`/${props.AnotherLinkUrl}`}>{props.AnotherLink}</Link>
            </li>
          </>
        )}
        <li>
          <FontAwesomeIcon icon={faLongArrowAltRight} />
        </li>
        <li>{props.PageTitle}</li>
      </ul>
    </div>
  );
};

export default PageTitle;
