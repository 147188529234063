import {
  OFFER_SUCCESS,
  OFFER_FAIL,
  OFFER_LOAD_SUCCESS,
  OFFER_LOAD_FAIL,
  OFFER_UPDATE_SUCCESS,
  OFFER_UPDATE_FAIL,
  OFFER_DELETE_SUCCESS,
  OFFER_DELETE_FAIL,
  OFFER_ACTIVATE_SUCCESS,
  OFFER_ACTIVATE_FAIL,
  SET_ALERT,
} from "../types";
import axios from "axios";
import { setAlert } from "./alertAction";

export const getOffer = () => {
  return async (dispatch) => {
    try {
      const config = { header: { "Content-Type": "application/json" } };
      const res = await axios.get(`${window.appUrl}/api/offer/`, config);
      dispatch({ type: OFFER_LOAD_SUCCESS, payload: res?.data });
    } catch (err) {
      dispatch({ type: OFFER_LOAD_FAIL, payload: err?.response?.data.message });
    }
  };
};

export const createOffer = (offer) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/offer/insert`,
        { offer },
        config
      );
      dispatch(
        setAlert(SET_ALERT, {
          message: "Offer Added Successfully",
          alertType: "success",
        })
      );
      setTimeout(() => {
        window.location.replace("/admin/offers");
      }, 2000);
      dispatch({ type: OFFER_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err.response.data.message,
          alertType: "danger",
        })
      );
      dispatch({ type: OFFER_FAIL, payload: err.response.data });
    }
  };
};
export const updateOffer = (offer, id) => async (dispatch) => {
  try {
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.patch(
      `${window.appUrl}/api/offer/update/` + id,
      offer,
      config
    );
    dispatch(
      setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
    );
    dispatch({ type: OFFER_UPDATE_SUCCESS, payload: res.data });
    localStorage.removeItem("update_offer");
    window.location.replace("/admin/offers");
  } catch (err) {
    dispatch({ type: OFFER_UPDATE_FAIL, payload: err.message });
  }
};

export const deleteOffer = (id) => async (dispatch) => {
  try {
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.delete(`${window.appUrl}/api/offer/` + id, config);

    dispatch(
      setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
    );
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    dispatch({ type: OFFER_DELETE_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: OFFER_DELETE_FAIL, payload: err.message });
  }
};

export const activateOffer = (id) => async (dispatch) => {
  try {
    const config = { header: { "Content-Type": "application/json" } };
    const res = await axios.patch(
      `${window.appUrl}/api/offer/activateoffer/` + id,
      config
    );
    dispatch(
      setAlert(SET_ALERT, { message: res.data.message, alertType: "success" })
    );
    setTimeout(() => {
      window.location.reload();
    }, 1000);

    dispatch({ type: OFFER_ACTIVATE_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: OFFER_ACTIVATE_FAIL, payload: err.message });
  }
};
