import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../../partails/pageTitle";
import Header from "../../partails/header";
import ListSubscribed from "../../partails/listSubscribed";
import ListUnSubscribed from "../../partails/listUnsubscribed";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getUserSubscribedList,
  getUserUnsubscribedList,
} from "../../../../redux/_actions/listAction";
import "./index.css";

const Lists = () => {
  const dispatch = useDispatch();
  const user = useState(JSON.parse(localStorage.getItem("user_auth")));
  const DiscoverList = useSelector((state) => state.list.unSubscribedList);
  const activatedDiscoverList = DiscoverList?.data?.filter(
    (x) => x.isActive === true
  );
  const UserList = useSelector((state) => state.list.subscribedList);
  const activatedUserList = UserList?.data?.filter((x) => x.isActive === true);
  const [subscribePreview, setSubscribePreview] = useState(false);
  const [unSubscribePreview, setUnSubscribePreview] = useState(false);
  const [listDetail, setListDetail] = useState(0);
  const currentTime = new Date().getTime();
  const hourSeconds = 172800000;
  const subscribePreviewToggle = (item) => {
    setSubscribePreview(!subscribePreview);
    setListDetail(item);
  };
  const subscribeDetailPreviewToggle = () => {
    setSubscribePreview(!subscribePreview);
  };
  const unSubscribePreviewToggle = (item) => {
    setUnSubscribePreview(!unSubscribePreview);
    setListDetail(item);
  };
  const unSubscribeDetailPreviewToggle = () => {
    setUnSubscribePreview(!unSubscribePreview);
  };
  useEffect(() => {
    dispatch(getUserSubscribedList(user[0]._id));
    dispatch(getUserUnsubscribedList(user[0]._id));
  }, [dispatch]);
  return (
    <>
      <Header logo={"true"} />
      <PageTitle PageTitle={"My List"} />
      <div className="list">
        <div className="add-new-list">
          <h2> Discover New Lists</h2>
          {DiscoverList?.data?.length > 0 ? (
            <>
              {activatedDiscoverList?.map((row) => (
                <React.Fragment key={row?._id}>
                  {currentTime -
                    row.SubscribedUser.find((x) => x.UserId === user[0]._id)
                      ?.subscribeTime <
                  hourSeconds ? (
                    <label className="new-list" key={row?._id}>
                      <span className="short-message">
                        You can subscribe it after 48 hours
                      </span>
                      {row?.ListName}
                    </label>
                  ) : (
                    <label
                      className="new-list"
                      key={row?._id}
                      onClick={(e) => subscribePreviewToggle(row)}
                    >
                      <span>
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                      {row?.ListName}
                    </label>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <h2 className="no-data-found">NO NEW list</h2>
          )}
        </div>
        <div className="my-list">
          <h2>My Lists</h2>
          {activatedUserList?.length > 0 ? (
            <>
              {UserList?.data?.map((row) => (
                <label
                  className="new-list"
                  key={row?._id}
                  onClick={(e) => unSubscribePreviewToggle(row)}
                >
                  <span>
                    <FontAwesomeIcon icon={faMinus} />
                  </span>
                  {row?.ListName}
                </label>
              ))}
            </>
          ) : (
            <h2 className="no-data-found">You Didn't subscribe any list</h2>
          )}
        </div>
      </div>
      {subscribePreview && (
        <ListSubscribed
          listDetail={listDetail}
          subscribeDetailPreviewToggle={subscribeDetailPreviewToggle}
        />
      )}
      {unSubscribePreview && (
        <ListUnSubscribed
          listDetail={listDetail}
          unSubscribeDetailPreviewToggle={unSubscribeDetailPreviewToggle}
        />
      )}
    </>
  );
};

export default Lists;
