import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { subscribeList } from "../../../../redux/_actions/listAction";
import "./index.css";

const ListSubscribed = (props) => {
  const dispatch = useDispatch();
  const user = useState(JSON.parse(localStorage.getItem("user_auth")));
  const handleHide = () => {
    props.subscribeDetailPreviewToggle();
  };
  const isActive = "true";
  const message = `Congratulation! \nyou sucessfully subscribed ${props.listDetail.ListName} List.\n${window.appUrl}/\nvisit our website for more detail.\nRegards,\nTicket text`;

  const SubscribeList = () => {
    dispatch(
      subscribeList(
        props.listDetail._id,
        user[0]._id,
        isActive,
        user[0].phoneNumber,
        message
      )
    );
  };
  return (
    <section className="list-subscribe-wrapper">
      <div className="list-subscribe">
        <span onClick={handleHide}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <button onClick={SubscribeList}>Subscribe</button>
      </div>
    </section>
  );
};

export default ListSubscribed;
