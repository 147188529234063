import {
  PAYMENT_DETAIL_SUCCESS,
  PAYMENT_DETAIL_FAIL,
  PAYMENT_DETAIL_ADMIN_SUCCESS,
  PAYMENT_DETAIL_ADMIN_FAIL,
  PAYMENT_UPDATE_CUSTOMER_SUCCESS,
  PAYMENT_UPDATE_CUSTOMER_FAIL,
  SET_ALERT,
} from "../types";
import axios from "axios";
import { setAlert } from "./alertAction";
import { sendCode } from "./smsServiceAction";
import { createUserr } from "./userAction";

export const createUser = (user) => {
  return async (dispatch) => {
    const userr = JSON.parse(localStorage.getItem("user_detail"));
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/payment/createpayment`,
        { user },
        config
      );
      localStorage.setItem(
        "user_cust",
        JSON.stringify(res.data?.data?.customer?.id)
      );
      dispatch(sendCode(userr.phoneNumber));
      dispatch({ type: PAYMENT_DETAIL_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err?.response?.data?.message,
          alertType: "danger",
        })
      );
      dispatch({ type: PAYMENT_DETAIL_FAIL, payload: err?.response?.data });
    }
  };
};

export const createUserByAdmin = (user) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/payment/createpayment`,
        { user },
        config
      );
      dispatch(createUserr(user, res.data?.data?.customer?.id));
      dispatch({ type: PAYMENT_DETAIL_ADMIN_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err?.response?.data?.message,
          alertType: "danger",
        })
      );
      dispatch({
        type: PAYMENT_DETAIL_ADMIN_FAIL,
        payload: err?.response?.data,
      });
    }
  };
};

export const updateUser = (user) => {
  return async (dispatch) => {
    const config = { header: { "Content-Type": "application/json" } };
    try {
      const res = await axios.post(
        `${window.appUrl}/api/payment/updatecustomer`,
        { user },
        config
      );
      dispatch(
        setAlert(SET_ALERT, {
          message: res.data.message,
          alertType: "success",
        })
      );
      setTimeout(() => {
        window.location.replace("/accountsetting");
      }, 1000);
      dispatch({ type: PAYMENT_UPDATE_CUSTOMER_SUCCESS, payload: res.data });
    } catch (err) {
      dispatch(
        setAlert(SET_ALERT, {
          message: err?.response?.data?.message,
          alertType: "danger",
        })
      );
      dispatch({
        type: PAYMENT_UPDATE_CUSTOMER_FAIL,
        payload: err?.response?.data,
      });
    }
  };
};
