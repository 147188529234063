import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/_actions/alertAction";
import { SET_ALERT } from "../../../../redux/types";
import { createUser } from "../../../../redux/_actions/paymentAction";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import CreditCardInput from "react-credit-card-input";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../../../assets/logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import "./index.css";
const Signup = () => {
  const dispatch = useDispatch();
  const [agreeCheck, setagreeCheck] = useState(false);
  const [userNumber, setUserNumber] = useState("");

  function userNumberChange(e) {
    setUserNumber(e);
    setError({ ...Error, phoneNumberError: "false" });
  }
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    dateOfBirth: "",
    email: "",
    password: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    cardNumber: "",
    cardExpiryMonth: "",
    cardExpiryYear: "",
    cardCVV: "",
    cardZip: "",
    role: "user",
    isActive: "true",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [captchaValue, setCaptchaValue] = useState();
  const [Error, setError] = useState({
    firstNameError: "",
    lastNameError: "",
    phoneNumberError: "",
    dateOfBirthError: "",
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
    billingAddressError: "",
    billingCityError: "",
    billingStateError: "",
    cardNumberError: "",
    cardExpiryYearError: "",
    cardCVVError: "",
    cardZipError: "",
  });
  const {
    firstName,
    lastName,
    dateOfBirth,
    email,
    password,
    cardNumber,
    cardExpiryYear,
    cardCVV,
    cardZip,
    billingAddress,
    billingCity,
    billingState,
  } = newUser;
  const onChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
    setError({ ...Error, [e.target.name + "Error"]: "false" });
  };
  const emailOnChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value.toString().toLowerCase(),
    });
    setError({ ...Error, [e.target.name + "Error"]: "false" });
  };
  const confirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
    setError({ ...Error, [e.target.name + "Error"]: "false" });
  };
  const captchaOnChange = (e) => {
    setCaptchaValue(e);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (userNumber === "") {
      setError({ ...Error, phoneNumberError: "true" });
    } else if (firstName === "") {
      setError({ ...Error, firstNameError: "true" });
    } else if (lastName === "") {
      setError({ ...Error, lastNameError: "true" });
    } else if (email === "") {
      setError({ ...Error, emailError: "true" });
    } else if (dateOfBirth === "") {
      setError({ ...Error, dateOfBirthError: "true" });
    } else if (password === "") {
      setError({ ...Error, passwordError: "true" });
    } else if (confirmPassword === "") {
      setError({ ...Error, confirmPasswordError: "true" });
    } else if (cardNumber === "") {
      setError({ ...Error, cardNumberError: "true" });
    } else if (cardExpiryYear === "") {
      setError({ ...Error, cardExpiryYearError: "true" });
    } else if (cardCVV === "") {
      setError({ ...Error, cardCVVError: "true" });
    } else if (cardZip === "") {
      setError({ ...Error, cardZipError: "true" });
    } else if (billingAddress === "") {
      setError({ ...Error, billingAddressError: "true" });
    } else if (billingCity === "") {
      setError({ ...Error, billingCityError: "true" });
    } else if (billingState === "") {
      setError({ ...Error, billingStateError: "true" });
    } else if (cardExpiryYear.length < 5) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Invalid Expiry Detail.",
          alertType: "danger",
        })
      );
    } else if (password.length < 8) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Password should be greater than 8 characters.",
          alertType: "danger",
        })
      );
    } else if (confirmPassword !== password) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Password and confirm password doesnot match",
          alertType: "danger",
        })
      );
    } else if (captchaValue === undefined || captchaValue === null) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Recaptcha is required",
          alertType: "danger",
        })
      );
    } else if (agreeCheck === false) {
      dispatch(
        setAlert(SET_ALERT, {
          message: "Please agree our terms & condition and privacy policy",
          alertType: "danger",
        })
      );
    } else {
      newUser.phoneNumber = userNumber;
      if (newUser.cardExpiryYear.length > 5) {
        let CardArray = newUser.cardExpiryYear.split("/");
        newUser.cardExpiryMonth = CardArray[0].replace(/\s/g, "");
        newUser.cardExpiryYear = CardArray[1].replace(/\s/g, "");
      }
      localStorage.setItem("user_detail", JSON.stringify(newUser));
      dispatch(createUser(newUser));
    }
  };
  return (
    <>
      <div className="signup-wrapper">
        <form className="signup" onSubmit={onSubmit}>
          <div className="logo-box">
            <img className="w-100" src={Logo} alt="" />
          </div>
          <Container>
            <Row>
              <Col md={12}>
                ,<label>Phone Number</label>
                <PhoneInput
                  international
                  defaultCountry="US"
                  countryCallingCodeEditable={false}
                  placeholder="Enter phone number"
                  name="userNumber"
                  value={userNumber}
                  onChange={userNumberChange}
                />
                {Error.phoneNumberError === "true" && (
                  <span className="errormessage ">
                    Phone Number is required
                  </span>
                )}
              </Col>
              <Col md={6}>
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={onChange}
                  placeholder="First Name"
                />
                {Error.firstNameError === "true" && (
                  <span className="errormessage ">First Name is required</span>
                )}
              </Col>
              <Col md={6}>
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={onChange}
                  placeholder="Last Name"
                />
                {Error.lastNameError === "true" && (
                  <span className="errormessage ">Last Name is required</span>
                )}
              </Col>
              <Col md={6}>
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={emailOnChange}
                  placeholder="Email Address"
                />
                {Error.emailError === "true" && (
                  <span className="errormessage ">
                    Email Address is required
                  </span>
                )}
              </Col>
              <Col md={6}>
                <label>Date Of Birth</label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={dateOfBirth}
                  onChange={onChange}
                />
                {Error.dateOfBirthError === "true" && (
                  <span className="errormessage ">Dob is required</span>
                )}
              </Col>
              <Col md={6}>
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Password"
                />
                {Error.passwordError === "true" && (
                  <span className="errormessage ">Password is required</span>
                )}
              </Col>
              <Col md={6}>
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={confirmPassChange}
                  placeholder="Confirm Password"
                />
                {Error.confirmPasswordError === "true" && (
                  <span className="errormessage ">
                    Confirm Password is required
                  </span>
                )}
              </Col>
              <Col md={12} className="card-detail">
                <label>Card Detail</label>
                <CreditCardInput
                  cardNumberInputProps={{
                    name: "cardNumber",
                    value: cardNumber,
                    onChange: onChange,
                  }}
                  cardExpiryInputProps={{
                    name: "cardExpiryYear",
                    value: cardExpiryYear,
                    onChange: onChange,
                  }}
                  cardCVCInputProps={{
                    name: "cardCVV",
                    value: cardCVV,
                    onChange: onChange,
                  }}
                  cardZipInputProps={{
                    name: "cardZip",
                    value: cardZip,
                    onChange: onChange,
                  }}
                  fieldClassName="card-input"
                />
                {Error.cardNumberError === "true" && (
                  <span className="errormessage ">Card Number is required</span>
                )}
                {Error.cardExpiryYearError === "true" && (
                  <span className="errormessage ">
                    Card Expiry Year is required
                  </span>
                )}
                {Error.cardCVVError === "true" && (
                  <span className="errormessage ">CVC is required</span>
                )}
                {Error.cardZipError === "true" && (
                  <span className="errormessage ">Zip Code is required</span>
                )}
              </Col>
              <Col md={12}>
                <label>Billing Address</label>
                <input
                  type="text"
                  name="billingAddress"
                  value={billingAddress}
                  onChange={onChange}
                  placeholder="Billing Address"
                />
                {Error.billingAddressError === "true" && (
                  <span className="errormessage">
                    Billing Address is required
                  </span>
                )}
              </Col>
              <Col md={6}>
                <label>Billing City</label>
                <input
                  type="text"
                  name="billingCity"
                  value={billingCity}
                  onChange={onChange}
                  placeholder="Billing City"
                />
                {Error.billingCityError === "true" && (
                  <span className="errormessage">Billing City is required</span>
                )}
              </Col>
              <Col md={6}>
                <label>Billing State</label>
                <input
                  type="text"
                  name="billingState"
                  value={billingState}
                  onChange={onChange}
                  placeholder="Billing State"
                />
                {Error.billingStateError === "true" && (
                  <span className="errormessage">
                    Billing State is required
                  </span>
                )}
              </Col>
              <Col md={12}>
                <ReCAPTCHA
                  style={{ marginTop: "40px" }}
                  sitekey="6LdaV6EcAAAAAFmJeDBZqR_xz0UR3Jxw5mCD7F-f"
                  onChange={captchaOnChange}
                />
                <div className="agree-statement ">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setagreeCheck(e.target.checked);
                    }}
                  />
                  <span>
                    By continuing, you agree to Baron Brother Production inc.
                    <Link to="/"> Terms of Use </Link> and
                    <Link to="/"> Privacy Policy</Link>. You consent to receive
                    phone calls and SMS messages from Brother Production inc to
                    provide updates on your queries and/or for marketing
                    purposes. Message frequency depends on your activity. You
                    may opt-out by texting "STOP". Message and data rates may
                    apply.
                  </span>
                </div>
              </Col>
              <Col md={12}>
                <button>Join Now</button>
                <p>
                  Already have an account?
                  <Link to="/login"> Login</Link>
                </p>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  );
};
export default Signup;
